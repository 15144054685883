import React, { useRef, useState } from 'react';
import { MdFormatColorText, MdFormatColorFill } from 'react-icons/md';
import { CirclePicker } from 'react-color';

import { colors } from '../../../../defaultColors.js';
import { addMarkData, activeMark } from '../../utils/SlateUtilityFunctions.js';
import { Transforms } from 'slate';
import usePopup from '../../utils/usePopup';

import check from '../../../../assets/img/check.svg';

const logo = {
	color: <MdFormatColorText size={20} />,
	bgColor: <MdFormatColorFill size={20} />,
};
const ColorPicker = ({ format, editor }) => {
	const [selection, setSelection] = useState();
	const [hexValue, setHexValue] = useState('');
	const [validHex, setValidHex] = useState();
	const colorPickerRef = useRef(null);
	const [showOptions, setShowOptions] = usePopup(colorPickerRef);

	const isValideHexSix = new RegExp('^#[0-9A-Za-z]{6}');
	const isValideHexThree = new RegExp('^#[0-9A-Za-z]{3}');

	const handleChangeColor = (color) => {
		const clickedColor = color['hex'];
		selection && Transforms.select(editor, selection);

		addMarkData(editor, { format, value: clickedColor });
		setShowOptions(false);
	};

	const toggleOption = () => {
		setSelection(editor.selection);
		setShowOptions(prev => !prev);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!validHex) return;
		selection && Transforms.select(editor, selection);

		addMarkData(editor, { format, value: hexValue });
		setShowOptions(false);
		setValidHex('');
		setHexValue('');

	};

	const handleHexChange = (e) => {
		e.preventDefault();
		const newHex = e.target.value;
		setValidHex(isValideHexSix.test(newHex) || isValideHexThree.test(newHex));
		setHexValue(newHex);
	};

	return <div className='color-picker popup-wrapper' ref={colorPickerRef}>
		<button style={{ color: showOptions ? 'black' : activeMark(editor, format), opacity: '1' }} className={showOptions ? 'mini_btn clicked' : 'mini_btn'} onClick={toggleOption}>{logo[format]}</button>
		{showOptions &&
            <div className='acrylic popup shadow is-absolute'>
            	<CirclePicker circleSize={25}
            		width={350}
            		circleSpacing={10}
            		className='mt-4'
            		color={activeMark(editor, format)}
            		colors={colors}
            		onChange={handleChangeColor}
            	/>
            	<span className='my-3 space'></span>
            	<form onSubmit={handleFormSubmit}>
            		<div className='round_size' style={{ background: validHex ? hexValue : '#000000' }}></div>
            		<input type='text' placeholder='#000000' value={hexValue} onChange={handleHexChange} style={{ border: validHex === false ? '2px solid red' : '2px solid var(--transparent)' }} />
            		<button className='green' style={{ color: validHex ? 'green' : '' , width: 52}} type={'submit'}>
            			<img className='svg_white check' src={check} />
            		</button>
            	</form>
            </div>
		}
	</div>;
};

export default ColorPicker;
