import React, { useCallback, useState } from 'react';
import update from 'immutability-helper';
import { SpinnerCircularFixed } from 'spinners-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DisruptionBlock from '../helpers/DisruptionBlock';

import arrow_left from '../assets/img/arrow-left.svg';
import del from '../assets/img/delete.svg';
import add from '../assets/img/add.svg';
import reset from '../assets/img/reset.svg';
import save from '../assets/img/save.svg';

function DisruptionsEditor({ data, updateData, handleOnChange }) {
	const default_disposition = {
		'title': '',
		'elements': [],
	};

	function sync(value) {
		handleOnChange({ 'disruptions': { 'disposition': { $set: value } } });
	}

	async function handleUpdate(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingUpdate(true);
		const { code, response } = await updateData('get_disruptions_disposition', '');

		if (code === 200) {
			setDisposition(response);
			sync(response);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingUpdate(false);
	}

	async function handleGetDefault(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingDefault(true);
		const { code, response } = await updateData('get_default_disruptions_disposition', '');

		if (code === 200) {
			setDisposition(response);
			sync(response);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingDefault(false);
	}

	async function handleSave(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingSave(true);
		const { code, response } = await updateData('set_disruptions_disposition', disposition);

		if (code === 200) {
			toast.success('Enregistré avec succès', toast_error);
			setDisposition(response);
			sync(response);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingSave(false);
	}

	function handleReset(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setDisposition((prevState) => update(prevState, { $set: [] }));
		sync([]);
	}

	/* --- */

	function handleRemoveClass(event, index) {
		event.preventDefault();
		event.currentTarget.blur();

		setDisposition((prevState) => update(prevState, { $splice: [[index, 1]] }));
	}

	function handleAddClass(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setDisposition((prevState) => update(prevState, { $push: [default_disposition] }));
	}

	function handleChangeClass(value, index, type) {
		setDisposition((prevState) => update(prevState, { [index]: { [type]: { $set: value } } }));
	}

	const moveCard = useCallback((dragIndex, hoverIndex) => {
		setDisposition((prevState) =>
			update(prevState, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevState[dragIndex]],
				],
			}),
		);
	}, []);

	const moveInfo = useCallback((dragIndex, hoverIndex, index) => {
		setDisposition((prevState) =>
			update(prevState, {
				[index]: {
					'elements': {
						$splice: [
							[dragIndex, 1],
							[hoverIndex, 0, prevState[index].elements[dragIndex]],
						],
					},
				},
			}),
		);
	}, []);

	const handleDelete = useCallback((item, id) => {
		setDisposition((prevState) =>
			update(prevState, { [id]: { 'elements': { $splice: [[item.index, 1]] } } }));
	}, []);

	function handleAddElement(logo) {
		setDisposition((prevState) => update(prevState, { [idForModal]: { 'elements': { $push: [logo] } } }));
		setIsOpenModal(false);
		setIdForModal('');
	}

	function handleOnChangeValue(event) {
		setInputValue(event.target.value);
	}

	function setModal(value) {
		setIsOpenModal(true);
		setIdForModal(value);
		setInputValue('');
	}

	const modal = () => <div className='modal-back'>
		<div className='modal'>

			<div className='is-block'>
				<input
					autoFocus
					className='left is-inline-block search'
					onChange={handleOnChangeValue}
					placeholder='Ajouter ou rechercher une classification'
					type='text'
					value={inputValue}
				/>
				<div className='space' />
				{
					inputValue != '' &&
					<button className='fluent_btn2 is-inline-flex left' onClick={() => handleAddElement(inputValue)}>
						<img className='svg' src={add} />
						<span>Ajouter <b>{inputValue}</b></span>
					</button>
				}
			</div>
			<div className='scrollable'>
				{
					filteredData.map((logo) => <button className='fluent_btn2 is-inline-flex left' key={logo} onClick={() => handleAddElement(logo)}>
						<span>{logo}</span>
					</button>)
				}
			</div>

			<div className='footer'>
				<button onClick={() => setIsOpenModal(false)} style={{ width: 120 }}>
					<img alt='Fermer' className='svg' src={del} />
					<span>Fermer</span>
				</button>
			</div>
		</div>
	</div>;

	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
	const [isLoadingDefault, setIsLoadingDefault] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [idForModal, setIdForModal] = useState('');

	const [disposition, setDisposition] = useState(data.disruptions.disposition);

	let list = [];
	disposition.forEach(element => list.push(...element.elements));
	list = data.logos.class.filter(item => !list.includes(item));
	const filteredData = list.filter(item => item.toLowerCase().includes(inputValue.toLowerCase()));

	return (
		<div>
			<Link to='/settings/disruptions' className='btn fluent_btn is-inline-flex' style={{ width: '105px' }} >
				<img className='svg' src={arrow_left} /> <span>Retour</span>
			</Link>
			<br />
			<div className='ribbon'>
				<button className=' is-inline-flex' onClick={handleReset} style={{ width: '140px' }}>
					<img className='svg' src={del} />
					<span>Tout effacer</span>
				</button>

				<button className='is-inline-flex' onClick={handleGetDefault} style={{ width: '110px' }}>
					{
						isLoadingDefault
							? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
							: <img className='svg' src={reset} />
					}
					<span>Defaut</span>
				</button>

				<button className='is-inline-flex' onClick={handleUpdate} style={{ width: '145px' }}>
					{
						isLoadingUpdate
							? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
							: <img className='svg' src={reset} />
					}
					<span>Actualiser</span>
				</button>

				<button className=' is-inline-flex' onClick={handleSave} style={{ width: '145px' }}>
					{
						isLoadingSave
							? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
							: <img className='svg' src={save} />
					}
					<span>Sauvegarder</span>
				</button>
			</div>

			<DndProvider backend={HTML5Backend}>
				{
					disposition.map((element, index) => <DisruptionBlock
						data={data}
						element={element}
						handleChangeClass={handleChangeClass}
						handleDelete={handleDelete}
						handleOnChange={handleOnChange}
						id={index}
						index={index}
						key={index}
						moveCard={moveCard}
						moveInfo={moveInfo}
						onRemove={handleRemoveClass}
						setModal={setModal}
					/>
					)
				}
			</DndProvider>

			<button className='fluent_btn is-inline-flex ml-5 mt-2 mb-4 left' onClick={handleAddClass} style={{ width: 'calc(100% - 36px)' }}>
				<img className='svg' src={add} />
				<span>Ajouter une catégorie</span>
			</button>

			{
				isOpenModal && modal()
			}

			<br />
		</div>
	);
}

export default DisruptionsEditor;