/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toast_error } from './styles';

import Loading from './Loading';

import logo from './assets/img/logo/Logo_lite.png';

// eslint-disable-next-line no-unused-vars
import jdata from './data.json';

function Nav({ children, data, setData, isLoading, setIsLoading, setModal, setError, base_url }) {

	React.useEffect(() => {
		getData();
	}, []);

	const goToLogin = () => {
		window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
	};

	const getData = async () => {
		setIsLoading(true);

		try {
			const res = await fetch(`${base_url}?account`, {
				method: 'get',
				headers: { 'Content-Type': 'application/json' },
			});

			if (res.status === 200) {
				const response = await res.json();
				setData(response);
				setIsLoading(false);
			} else if (res.status === 401) {
				toast.error('Identifiant de session invalide, veuillez vous reconnecter', toast_error);
				// goToLogin();
				toast.info('DEV_MODE', toast_error);
				setData(jdata);
				setIsLoading(false);
			} else {
				toast.error('Le serveur a rencontré une erreur. ', toast_error);
				setError(true);
			}
		} catch (error) {
			toast.error('Récupération des informations impossible.', toast_error);
			setError(true);
			//TODO toast.info('DEV_MODE', toast_error);
			//TODO setData(jdata);
			//TODO setIsLoading(false);
		}
	};

	if (isLoading || data.account === null) {
		return <Loading />;
	}

	const user = data.account.name;

	return <div className='body'>
		<header>
			<div className='nav-head'>
				<Link to={'/index'}>
					<img src={logo} className='icone' />
				</Link>
				<span> MyLines </span>
				<span className='extend'>Bonjour, {user} </span>
			</div>
			<div className='nav-link'>
				<Link to={`/company/${user}/home`} className='link_white'>
					Ma compagnie
				</Link>
				<Link to='/settings' className='link_white'>
					Mon compte
				</Link>
				<span onClick={() => setModal('logout')} className='link_white'> Déconnexion </span>
			</div>
			<div className='nav-ham' onClick='menuOp()'>
				<img src='assets/img/menu.svg' className='svg_white icone' />
			</div>
		</header>
		<div className='content'>
			{children}
			<Outlet />
		</div>
	</div>;
}

export default Nav;