import React from 'react';

// import NotFound from './components/NotFound';

import home from './assets/img/home.svg';
import account from './assets/img/account.svg';
import lock from './assets/img/lock.svg';
import apps from './assets/img/apps.svg';
import paint from './assets/img/paint.svg';
import sets from './assets/img/sets.svg';
import steam_engine from './assets/img/steam-engine.svg';
import about from './assets/img/about.svg';
import map from './assets/img/map.svg';
import image from './assets/img/image.svg';
import page from './assets/img/page.svg';
import visibility from './assets/img/visibility.svg';
import rules from './assets/img/rules.svg';
import settings from './assets/img/settings.svg';
import admin from './assets/img/admin.svg';
import box_important from './assets/img/box-important.svg';
import hdd from './assets/img/hdd.svg';
import no_key from './assets/img/no_key.svg';
import key from './assets/img/key.svg';
import bug from './assets/img/bug.svg';
import event_log from './assets/img/event-log.svg';

import Account from './components/Account';
import Apps from './components/Apps';
import Disruptions from './components/Disruptions';
import DisruptionsEdit from './components/DisruptionsEdit';
import Home from './components/Home';
import Images from './components/Images';
import Logos from './components/Logos';
import Maps from './components/Maps';
import Pages from './components/Pages';
import Personalization from './components/Personalization';
import Security from './components/Security';
import Theming from './components/Theming';
import Trains from './components/Trains';
import Embeds from './components/Embeds';

import Accessibility from './components/help/Accessibility';
import CGU from './components/help/CGU';

import Admin from './components/admin/Admin';
import Accounts from './components/admin/Accounts';
import AccountDetails from './components/admin/AccountDetails';
import Check from './components/admin/Check';
import Storage from './components/admin/Storage';
import FailedConnexions from './components/admin/FailedConnexions';
import SignupKey from './components/admin/SignupKey';
import CSS from './components/admin/CSS';
import Logs from './components/admin/Logs';

const settings_route = [
	{
		'id': 'home',
		'visible': true,
		'component': Home,
		'img': home,
		'label': 'Vue d’ensemble',
		'render': (props) => <Home {...props} authed={true} />
	},
	{
		'id': 'account',
		'visible': true,
		'component': Account,
		'img': account,
		'label': 'Compte',
	},
	{
		'id': 'security',
		'visible': true,
		'component': Security,
		'img': lock,
		'label': 'Sécurité',
	},
	{
		'id': 'apps',
		'visible': true,
		'component': Apps,
		'img': apps,
		'label': 'Applications',
	},
	{
		'id': 'personalization',
		'visible': true,
		'component': Personalization,
		'img': paint,
		'label': 'Personnalisation',
	},
	{
		'id': 'theming',
		'visible': true,
		'component': Theming,
		'img': sets,
		'label': 'Options d’affichage',
	},
	{
		'id': 'trains',
		'visible': true,
		'component': Trains,
		'img': steam_engine,
		'label': 'Trains',
	},
	{
		'id': 'disruptions',
		'visible': true,
		'exact': true,
		'for': ['disruptions/edit'],
		'component': Disruptions,
		'img': about,
		'label': 'Info Trafic',
	},
	{
		'id': 'disruptions/edit',
		'visible': false,
		'exact': true,
		'component': DisruptionsEdit,
		'img': about,
		'label': 'Personnalisation de l\'info trafic',
	},
	{
		'id': 'maps',
		'visible': true,
		'component': Maps,
		'img': map,
		'label': 'Plan',
	},
	{
		'id': 'images',
		'visible': true,
		'exact': true,
		'for': ['images/class', 'images/embed'],
		'component': Images,
		'img': image,
		'label': 'Images',
	},
	{
		'id': 'images/class',
		'visible': false,
		'exact': true,
		'component': Logos,
		'img': image,
		'label': 'Logos de classification',
	},
	{
		'id': 'images/embed',
		'visible': false,
		'exact': true,
		'component': Embeds,
		'img': image,
		'label': 'Logos des infogares',
	},
	{
		'id': 'pages',
		'visible': true,
		'component': Pages,
		'img': page,
		'label': 'Mes Pages',
	}
];

const help_route = [
	{
		'id': 'accessibility',
		'visible': true,
		'component': Accessibility,
		'img': visibility,
		'label': 'Accessibilité',
	},
	{
		'id': 'cgu',
		'visible': true,
		'component': CGU,
		'img': rules,
		'is-hideed-label': true,
		'label': 'Conditions générales',
	}
];

const admin_route = [
	{
		'id': 'admin',
		'visible': true,
		'component': Admin,
		'img': settings,
		'label': 'Options admin',
	},
	{
		'id': 'accounts',
		'visible': true,
		'is_beta': true,
		'for': ['accounts/details'],
		'component': Accounts,
		'img': admin,
		'label': 'Gestion des comptes',
	},
	{
		'id': 'accounts/:details',
		'visible': false,
		'component': AccountDetails,
		'img': admin,
		'label': 'Details du compte',
	},
	{
		'id': 'check',
		'visible': true,
		'component': Check,
		'img': box_important,
		'label': 'Analyse',
	},
	{
		'id': 'storage',
		'visible': true,
		'component': Storage,
		'img': hdd,
		'label': 'Stockage',
	},
	{
		'id': 'bdd',
		'visible': true,
		'component': FailedConnexions,
		'img': no_key,
		'label': 'Tentatives de connexions',
	},
	{
		'id': 'signup_key',
		'visible': true,
		'is_beta': true,
		'component': SignupKey,
		'img': key,
		'label': 'Code de vérification',
	},
	{
		'id': 'bug',
		'visible': true,
		'is_beta': true,
		'component': CSS,
		'img': bug,
		'label': 'Style',
	},
	{
		'id': 'log',
		'visible': true,
		'component': Logs,
		'img': event_log,
		'label': 'Logs',
	}
];

export { settings_route, help_route, admin_route };