import React, {useState} from 'react';
import Toggle from 'react-toggle';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';

import help from '../assets/img/help.svg';

function Theming({ data, handleOnChange, updateData, setModal }) {
	async function handleChangePrice(event) {
		setIsLoadingPrice(true);
		const value = event.target.checked;
		const { code, response } = await updateData('show_price', value);

		if (code === 200) {
			handleOnChange({ 'theming': { 'show_price': { $set: (response['response']['value'] === true) } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingPrice(false);
	}
	async function handleChangeTrafic(event) {
		setIsLoadingTrafic(true);
		const value = event.target.checked;
		const { code, response } = await updateData('show_trafic', value);

		if (code === 200) {
			handleOnChange({ 'theming': { 'show_trafic': { $set: (response['response']['value'] === true) } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingTrafic(false);
	}
	async function handleChangeNavBar(event) {
		setIsLoadingNavBar(true);
		const value = event.target.checked;
		const { code, response } = await updateData('style_nav_bar', value);

		if (code === 200) {
			handleOnChange({ 'theming': { 'style_nav_bar': { $set: (response['response']['value'] === true) } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingNavBar(false);
	}

	const [isLoadingPrice, setIsLoadingPrice] = useState(false);
	const [isLoadingTrafic, setIsLoadingTrafic] = useState(false);
	const [isLoadingNavBar, setIsLoadingNavBar] = useState(false);

	return (
		<div className='block'>
			<h3>Personnalisez certains détails d’affichage</h3>
			<div className='is-block mb-6'>
Afficher les tarifs lors d’une recherche d’itinéraires
				<br /><br />
				<Toggle
					checked={data['theming']['show_price']}
					icons={false}
					id='show_price'
					onChange={handleChangePrice}
				/>
				{isLoadingPrice
					? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
					: <label htmlFor='visible'>{data['theming']['show_price'] ? 'Affiché' : 'Masqué'}</label>
				}
			</div>

			<div className='is-block mb-6'>
Affichage de l’info trafic lors d’une recherche d’itinéraires
				<br /><br />
				<Toggle
					checked={data['theming']['show_trafic']}
					icons={false}
					id='show_trafic'
					onChange={handleChangeTrafic}
				/>
				{isLoadingTrafic
					? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
					: <label htmlFor='visible'>{data['theming']['show_trafic'] ? 'Affiché' : 'Masqué'}</label>
				}

				<img className='img-help svg' onClick={() => setModal('help_show_trafic')} src={help} />
			</div>

			<div className='is-block mb-6'>
Options d’affichage de la barre de navigation
				<br /><br />
				<Toggle
					checked={data['theming']['style_nav_bar']}
					icons={false}
					id='style_nav_bar'
					onChange={handleChangeNavBar}
				/>
				{isLoadingNavBar
					? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
					: <label htmlFor='visible'>{data['theming']['style_nav_bar'] ? 'Affiché' : 'Masqué'}</label>
				}

				<img className='img-help svg' onClick={() => setModal('help_style_nav_bar')} src={help} />
			</div>
		</div>

	);
}

export default Theming;