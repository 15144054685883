import React from 'react';
import { Link } from 'react-router-dom';

import { Wallpaper } from './styles';

import highRisk from './assets/img/high-risk.png';

function DeleteAccount() {
	return <>
		<div className='Wallpaper' style={Wallpaper} />

		<div className='acrylic form' style={{ textAlign: 'left' }}>
			<div style={{ animation: '0.8s ease 0s 1 normal none running SettingsOptions' }}>
				<div className='center'>
					<img src={highRisk} className='center' style={{ maxWidth: '70%' }} />
				</div>
				<h3 className='center'>Supprimer votre compte</h3>
				<br/>
				<p>
                    En continuant, l’entièreté de vos données liée à MyLines seront effacés.
				</p>
				<p>
                    Seul des logs permettant de faire des vérifications de sécurité peuvent potentiellement être conservé. 
				</p>
				<br/>
				<div className='center'>
					<a className='btn red' href='delete_account'>
						<span >Obtenir le code</span>
					</a>

					<br/>

					<Link to='/settings/account' className='link'>
                        Annuler
					</Link>
				</div>
			</div>
		</div>
	</>;
}

export default DeleteAccount;