import React, { useState, useCallback } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


import LimitedInput from '../helpers/LimitedInput';

import Page from '../helpers/Page';

import help from '../assets/img/help.svg';
import save from '../assets/img/save.svg';
import reset from '../assets/img/reset.svg';
import insertPage from '../assets/img/insert-page.svg';
import del from '../assets/img/delete.svg';
import trash from '../assets/img/trash.svg';

import home from '../assets/img/home.svg';
import edit from '../assets/img/write_on_paper.svg';

// eslint-disable-next-line no-unused-vars
function Pages({ data, setModal, handleOnChange, updateData }) {

	async function handleSave(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingSave(true);

		handleOnChange({ 'pages': { $set: disposition } });

		const { code, response } = await updateData('set_pages', disposition);

		if (code === 200) {
			handleOnChange({ 'pages': { $set: response } });
			setDisposition(response);
			toast.success('Enregistré avec succès', toast_error);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingSave(false);
	}

	async function handleUpdate(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingUpdate(true);

		const { code, response } = await updateData('get_pages', '');

		if (code === 200) {
			handleOnChange({ 'pages': { $set: response } });
			setDisposition(response);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingUpdate(false);
	}

	async function handleInsertPage(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingInsertPage(true);

		handleOnChange({ 'pages': { $set: disposition } });

		const { code, response } = await updateData('create_page', disposition);

		if (code === 200) {
			handleOnChange({ 'pages': { $set: response } });
			setDisposition(response);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingInsertPage(false);
	}

	async function handleDeletePage(event) {
		event.preventDefault();
		event.currentTarget.blur();

		handleOnChange({ 'pages': { $set: disposition } });

		const { code, response } = await updateData('delete_page', {page: currentPage, disposition});

		if (code === 200) {
			handleOnChange({ 'pages': { $set: response } });
			setDisposition(response);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setCurrentPage(null);
	}

	const moveCard = useCallback((dragIndex, hoverIndex) => {
		setDisposition((prevState) =>
			update(prevState, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevState[dragIndex]],
				],
			}),
		);
		handleOnChange({ 'pages': { $set: disposition } });
	}, []);

	function handleRemovePage(id) {
		setCurrentPage(id);
		setModal('remove_page');
	}

	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [isLoadingInsertPage, setIsLoadingInsertPage] = useState(false);

	const [currentPage, setCurrentPage] = useState(null);

	const [disposition, setDisposition] = useState(data.pages);

	return <div className='is-block'>
        Voici l’arborescence des toutes vos pages, vous pouvez les supprimer, renommer et les éditer. <br />
        Vous pouvez changer l’ordre de vos pages et en les glissant.

		{
			currentPage && <div className='modal-back'>
				<div className='small-modal'>

					<div className='is-block'>
						<h2>Supprimer la page</h2>
						<div className='space' />
					</div>
					<br />
					<p>
                        Etes-vous sur de vouloir supprimer cette page ?
						<br /><br />
                        La page <b>{currentPage.name}</b> et l’ensemble de ses données seront supprimé.
						<br /><br />
						<b>Cette action est irréversible.</b>
					</p>

					<div className='footer'>
						<button onClick={() => setCurrentPage(null)} style={{ width: 120 }}>
							<img alt='Annuler' className='svg' src={del} />
							<span>Annuler</span>
						</button>

						<button className='red' onClick={handleDeletePage} style={{ width: 130 }}>
							<img alt='Supprimer' className='svg_white' src={trash} />
							<span>Supprimer</span>
						</button>
					</div>
				</div>
			</div>
		}

		<img className='img-help svg' onClick={() => setModal('help_pages')} src={help} />
		<div className='block'>
			<div className='ribbon is-flex space-between'>
				<div className='is-inline-block'>
					<button className='is-inline-flex' onClick={handleUpdate} style={{ width: '145px' }}>
						{
							isLoadingUpdate
								? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
								: <img className='svg' src={reset} />
						}
						<span>Actualiser</span>
					</button>
					<button className=' is-inline-flex' onClick={handleSave} style={{ width: '145px' }}>
						{
							isLoadingSave
								? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
								: <img className='svg' src={save} />
						}
						<span>Sauvegarder</span>
					</button>
				</div>

				<button className=' is-inline-flex' onClick={handleInsertPage} style={{ width: '160px' }}>
					{
						isLoadingInsertPage
							? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
							: <img className='svg' src={insertPage} />
					}
					<span>Nouvelle Page</span>
				</button>
			</div>

			<span className='space' />

			<div className='Pages'>
				<div className='box p-0'>
					<img className='is-inline-flex is-relative svg ml-5' src={home} style={{ width: 30, height: 30, top: 8 }} />
					<LimitedInput
						className='is-inline-block ml-2 mr-2'
						disabled
						maxLength={50}
						style={{ width: '30%' }}
						type='text'
						value='Accueil'
					/>
					<Link to='/editpage/home' className='btn is-inline-flex is-relative'
						style={{ width: 105, height: 46, top: 8 }}>
						<img className='svg' src={edit} />
						<span>Editer</span>
					</Link>
				</div >


				<DndProvider backend={HTML5Backend}>
					{
						disposition.map((page, index) => <Page
							id={index}
							index={index}
							key={index}
							moveCard={moveCard}
							page={page}
							disposition={disposition}
							setDisposition={setDisposition}
							handleRemovePage={handleRemovePage}
						/>
						)
					}
				</DndProvider>
			</div>
		</div>
	</div>;
}

export default Pages;