/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import update from 'react-addons-update';
import { useParams, useNavigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';

import HeadBar from './editpage/HeadBar';
import DropZone from './editpage/DropZone';
import TrashDropZone from './editpage/TrashDropZone';
import { SideBarItem, SideBarSmallItem } from './editpage/SideBarItem';
import Row from './editpage/Row';
import EditModal from './EditModal';
import { handleMoveWithinParent, handleMoveToDifferentParent, handleMoveSidebarComponentIntoParent, handleRemoveItemFromLayout } from './editpage/helpers';

import { SIDEBAR_ITEMS, SIDEBAR_SMALL_ITEMS, SIDEBAR_LEGACY_ITEMS, SIDEBAR_SPEC_ITEMS, SIDEBAR_ITEM, COMPONENT, COLUMN } from './editpage/constants';
import shortid from 'shortid';
import { wallpaper } from '../styles';

import jData from '../pageData.json';

const EditPage = ({ data, updateData, handleOnChange }) => {
	const { idpage } = useParams();
	const navigate = useNavigate();

	const [layout, setLayout] = useState([]);
	const [components, setComponents] = useState({});
	const [modal, setModal] = useState(null);
	const [modalComponement, setModalComponement] = useState(null);

	const [page, setPage] = useState(idpage);
	const [pageData, setPageData] = useState(jData);
	// const [pageData, setPageData] = useState({ layout: [], components: {} });
	const [isMoving, setIsMoving] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

	function removeItemFromLayout(id) {
		let newLayout = layout;

		newLayout.forEach((row, rowIndex) => {
			row.children.forEach((column, columnIndex) => {
				column.children.forEach((component, index) => {
					if (component.id == id) {
						setLayout(layout => update(layout, { [rowIndex]: { 'children': { [columnIndex]: { 'children': { $splice: [[index, 1]] } } } } }));
					}
				});
			});
		});
	}

	function syncLayoutAndComponements(layout) {
		let newLayout = layout;
		for (let i = 0; i < newLayout.length; i++) {
			const row = newLayout[i];
			if (row.type == 'row' && row.children.length > 1) {
				for (let y = 0; y < row.children.length; y++) {
					const column = row.children[y];
					if (column.type == 'column' && column.children.length <= 0) {
						newLayout[i].children.splice(y, 1);
					}
				}
			}
		}

		let newComponents = {};
		newLayout.forEach(row => {
			newComponents[row.id] = components[row.id];
			row.children.forEach(column => {
				newComponents[column.id] = components[column.id];
				column.children.forEach(component => {
					newComponents[component.id] = components[component.id];
				});
			});
		});

		return { newLayout: newLayout, newComponents: newComponents };
	}

	const handleDropToTrashBin = useCallback(
		(dropZone, item) => {
			const splitItemPath = item.path.split('-');

			const { newLayout, newComponents } = syncLayoutAndComponements(handleRemoveItemFromLayout(layout, splitItemPath));

			setLayout(newLayout);
			setComponents(newComponents);
		},
		[layout]
	);

	const handleDrop = useCallback(
		(dropZone, item) => {
			const splitDropZonePath = dropZone.path.split('-');
			const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');

			const newItem = { id: item.id, type: item.type };
			if (item.type === COLUMN) {
				newItem.children = item.children;
			}

			// 1. Move sidebar item into page
			if (item.type === SIDEBAR_ITEM) {
				const newComponent = {
					id: shortid.generate(),
					...item.component
				};
				const newItem = {
					id: newComponent.id,
					type: COMPONENT,
				};
				const { newLayout, newComponents } = handleMoveSidebarComponentIntoParent(layout, splitDropZonePath, newItem);

				setComponents({
					...components,
					...newComponents,
					[newComponent.id]: newComponent
				});
				setLayout(newLayout);

				if (newComponent.needInit) {
					setModalComponement(newComponent.id);
					setModal(newComponent.type);
				}

				return;
			}

			const splitItemPath = item.path.split('-');
			const pathToItem = splitItemPath.slice(0, -1).join('-');

			// 2. Pure move (no create)
			if (splitItemPath.length === splitDropZonePath.length) {
				// 2.a. move within parent
				if (pathToItem === pathToDropZone) {
					setLayout(
						handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
					);
					return;
				}

				// 2.b. OR move different parent
				// TODO FIX columns. item includes children
				const { newLayout, newComponents } = handleMoveToDifferentParent(layout, splitDropZonePath, splitItemPath, newItem);
				setComponents({
					...components,
					...newComponents
				});
				setLayout(
					newLayout
				);

				return;
			}

			// 3. Move + Create
			const { newLayout, newComponents } = handleMoveToDifferentParent(layout, splitDropZonePath, splitItemPath, newItem);
			setComponents({
				...components,
				...newComponents
			});
			setLayout(
				newLayout
			);

			return;
		},
		[layout, components]
	);

	const renderRow = (row, currentPath) => <Row
		key={row.id}
		data={data}
		row={row}
		handleDrop={handleDrop}
		components={components}
		path={currentPath}
		setIsMoving={setIsMoving}
		setComponents={setComponents}
		isMoving={isMoving}
	/>;

	async function handleOnChangePage(event) {
		setPage(event.value);

		navigate(`/editpage/${event.value}`, { replace: true });
		await getPageData(event.value);
	}

	React.useEffect(() => {
		getPageData(page);
	}, []);

	async function handleUpdate(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingUpdate(true);
		await getPageData(page);
		setIsLoadingUpdate(false);
	}

	async function getPageData(id) {
		setIsLoading(true);
		const { code, response } = await updateData('get_page_data', id);

		if (code === 200) {
			if (response['content'] && response['content']['components'] && response['content']['layout']) {
				setPageData(response['content']);
				setComponents(response['content']['components']);
				setLayout(response['content']['layout']);
			} else {
				setPageData({ layout: [], components: {} });
				setComponents({});
				setLayout([]);
			}
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoading(false);
	}

	async function handleSave(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setModal('savepage');

		const { newLayout, newComponents } = syncLayoutAndComponements(layout);

		setComponents(newComponents);
		setLayout(newLayout);

		const content = { layout: newLayout, components: newComponents };

		setPageData(content);

		const value = {
			page,
			content
		};

		const { code, response } = await updateData('update_page_data', value);

		if (code !== 200) {
			toast.error(response['error']['details'], toast_error);
			setModal(null);
		}

		setTimeout(() => setModal(null), 2000);
	}


	return <DndProvider backend={HTML5Backend}>
		<div className='settings-pagecontainer'>
			{
				isLoading
					? <div className='page'>
						<div className='center'>
							<SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={50} speed={100} thickness={150} />
						</div>
					</div>
					: <div className='page' style={wallpaper(data.account.name)}>
						{layout.map((row, index) => {
							const currentPath = `${index}`;

							return <>
								<DropZone
									data={data}
									dropData={{
										path: currentPath,
										childrenCount: layout.length
									}}
									onDrop={handleDrop}
									path={currentPath}
									isMoving={isMoving}
								/>
								{renderRow(row, currentPath)}
							</>
							;
						})}
						<DropZone
							data={data}
							dropData={{
								path: `${layout.length}`,
								childrenCount: layout.length
							}}
							onDrop={handleDrop}
							isMoving={isMoving}
							isLast
						/>
					</div>
			}

		</div>

		<div className='settings-sidebar'>
			<h2>Insérer</h2>
			<div className='elements'>
				{Object.values(SIDEBAR_ITEMS).map((sideBarItem, index) => (
					<SideBarItem key={sideBarItem.id} data={sideBarItem} setIsMoving={setIsMoving} />
				))}
			</div>

			<br />
			<span className='hr' />
			<h3>Eléments de page</h3>
			<div className='elements'>
				{Object.values(SIDEBAR_SMALL_ITEMS).map((sideBarItem, index) => (
					<SideBarSmallItem key={sideBarItem.id} data={sideBarItem} setIsMoving={setIsMoving} />
				))}
			</div>

			<br />
			<span className='hr' />
			<h3>Eléments de Mylines</h3>
			<div className='elements'>
				{Object.values(SIDEBAR_LEGACY_ITEMS).map((sideBarItem, index) => (
					<SideBarSmallItem key={sideBarItem.id} data={sideBarItem} setIsMoving={setIsMoving} />
				))}
			</div>
			<br />
			<span className='hr' />
			<h3>Eléments spécifique</h3>
			<div className='elements'>
				{Object.values(SIDEBAR_SPEC_ITEMS).map((sideBarItem, index) => (
					<SideBarSmallItem key={sideBarItem.id} data={sideBarItem} setIsMoving={setIsMoving} />
				))}
			</div>
			<br />
		</div>

		{
			isMoving === 'true' && <TrashDropZone
				data={{ layout }}
				onDrop={handleDropToTrashBin}
			/>
		}
		<HeadBar
			handleOnChangePage={handleOnChangePage}
			handleUpdate={handleUpdate}
			isLoadingUpdate={isLoadingUpdate}
			handleSave={handleSave}
			isLoadingSave={isLoadingSave}
			isLoading={isLoading}
			data={data}
			page={page}
		/>
		<EditModal
			data={data}
			handleOnChange={handleOnChange}
			updateData={updateData}
			modal={modal}
			setModal={setModal}
			modalComponement={modalComponement}
			setComponents={setComponents}
			layout={layout}
			setLayout={setLayout}
			removeItemFromLayout={removeItemFromLayout}
		/>
	</DndProvider>;
};
export default EditPage;
