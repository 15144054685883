import React, { useEffect, useState } from 'react';
import { useSlate } from 'slate-react';
import Select from 'react-select';
import Icon from '../common/Icon';
import { toggleBlock, toggleMark, isMarkActive, addMarkData, isBlockActive, activeMark, activeSelect } from '../utils/SlateUtilityFunctions.js';
import useTable from '../utils/useTable.js';
import defaultToolbarGroups from './toolbarGroups.js';
import ColorPicker from '../Elements/Color Picker/ColorPicker';
import LinkButton from '../Elements/Link/LinkButton';
import Table from '../Elements/Table/Table';
import InTable from '../Elements/Table/InTable';

const Toolbar = () => {
	const editor = useSlate();
	const isTable = useTable(editor);
	const [toolbarGroups, setToolbarGroups] = useState(defaultToolbarGroups);
	useEffect(() => {
		let filteredGroups = [...defaultToolbarGroups];
		if (isTable) {
			filteredGroups = toolbarGroups.map(grp => (
				grp.filter(element => (
					!['table'].includes(element.type)
				))
			));
			filteredGroups = filteredGroups.filter(elem => elem.length);
		}
		setToolbarGroups(filteredGroups);
	}, [isTable]);
	const BlockButton = ({ format }) => {
		const active = isMarkActive(editor, format);
		return <button className={`mini_btn ${active ? 'active' : ''}`} format={format} onMouseDown={
			e => {
				e.preventDefault();
				toggleBlock(editor, format);
			}
		}>
			<Icon icon={format} />
		</button>;
	};
	const MarkButton = ({ format }) => {
		const active = isMarkActive(editor, format);
		return <button className={`mini_btn ${active ? 'active' : ''}`} format={format} onMouseDown={
			e => {
				e.preventDefault();
				toggleMark(editor, format);
			}
		}>
			<Icon icon={format} />
		</button>;
	};
	const Dropdown = ({ format, options }) => {
		return <div style={{ width: 140 }} className='mr-3'>
			<Select
				className='basic-single'
				classNamePrefix='select'
				classNames={{
					control: (state) => state.isFocused ? 'select active' : 'select',
					menu: () => 'select-list',
					singleValue: () => 'select-value',
					option: (state) => `select-option ${state.isSelected ? 'active' : ''}`,
					indicatorSeparator: () => 'select-separator',
				}}
				value={activeSelect(editor, format)}
				isSearchable={true}
				name='color'
				noOptionsMessage={() => 'Aucun résultat'}
				options={options}
				onChange={e => changeMarkData(e, format)}
			/>
		</div>;
	};
	const changeMarkData = (event, format) => {
		const value = event.value;
		addMarkData(editor, { format, value });
	};

	return (
		<div className='toolbar acrylic shadow'>
			{
				toolbarGroups.map((group, index) =>
					<span key={index} className='toolbar-group'>
						{
							group.map((element) => {
								switch (element.type) {
								case 'block':
									return <BlockButton key={element.id} {...element} />;
								case 'mark':
									return <MarkButton key={element.id} {...element} />;
								case 'dropdown':
									return <Dropdown key={element.id} {...element} />;
								case 'link':
									return <LinkButton key={element.id} active={isBlockActive(editor, 'link')} editor={editor} />;
								case 'color-picker':
									return <ColorPicker key={element.id} activeMark={activeMark} format={element.format} editor={editor} />;
								case 'table':
									return <Table key={element.id} editor={editor} />;
								case 'inTable':
									return isTable ? <InTable key={element.id} editor={editor} /> : null;
								default:
									return <button>Invalid Button</button>;
								}
							}
							)
						}
					</span>
				)
			}
		</div>
	);
};

export default Toolbar;