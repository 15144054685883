import React from 'react';
import { useSelected, useFocused } from 'slate-react';

const Image = ({ attributes, element, children }) => {
	const {url,width,height} = element;
	const selected = useSelected();
	const focused = useFocused();
	return (
		<div
			{...attributes}
			className='element-image'
			style={{display:'flex',boxShadow: selected && focused && '0 0 3px 3px lightgray'}}
			{...element.attr}
		>
			<div contentEditable={false} style={{width:width,height:height}}>
				<img alt={element.alt} src={url}/>
			</div>
			{children}
		</div>
	);
};
export default Image;