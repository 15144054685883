import React from 'react';
import Toggle from 'react-toggle';
import { useCookies } from 'react-cookie';

import Footer from '../../Footer';

function Accessibility() {
	const [cookies, setCookie] = useCookies(['Font']);
	const [toggle, setToggle] = React.useState(cookies.Font === 'Luciole');

	function handleOnToogleFont(e) {
		if (e.currentTarget.checked) {
			setCookie('Font', 'Luciole', { path: '/' });
		} else {
			setCookie('Font', null, { path: '/' });
		}
		setToggle(e.currentTarget.checked);
		refreshPage();
	}

	function refreshPage() {
		window.location.reload(false);
	}

	return <div className='doc'>
		<div className='block'>
			<form className='fluent_form left block'>
				<h3>Police</h3>
				<Toggle
					checked={toggle}
					icons={false}
					onChange={handleOnToogleFont}
				/>
				<label htmlFor='visible'>Utiliser la police d’écriture Luciole</label>
			</form>

			<div className='blue is-block'>
				<div className='is-block'>La police Luciole a été conçu spécifiquement pour les personnes malvoyantes.</div>
                ➜ <a className='link_blue' href='http://luciole-vision.com/' rel='noreferrer' target='_blank'> http://luciole-vision.com/</a>
			</div>
			<br /><br /><br />
			<span className='space' />
			<br />
			<Footer />
			<br /><br />
		</div>
	</div>;
}

export default Accessibility;