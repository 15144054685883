import React from 'react';
import { useDrag } from 'react-dnd';

import { SIDEBAR_ITEM } from './constants';

export const SideBarItem = ({ data, setIsMoving }) => {
	const [{ opacity }, drag] = useDrag({
		type: SIDEBAR_ITEM,
		item: () => {
			setIsMoving('new');
			return data;
		},
		collect: monitor => ({
			opacity: monitor.isDragging() ? 0.4 : 1
		}),
		end: () => {
			setIsMoving(false);
		},
	});

	return <div className='item large' ref={drag} style={{ opacity }}>
		<img src={data.img} alt='Texte' />
		<br />
		<span>{data.component.name}</span>
	</div>;
};

export const SideBarSmallItem = ({ data, setIsMoving }) => {
	const [{ opacity }, drag] = useDrag({
		type: SIDEBAR_ITEM,
		item: () => {
			setIsMoving('new');
			return data;
		},
		collect: monitor => ({
			opacity: monitor.isDragging() ? 0.4 : 1
		}),
		end: () => {
			setIsMoving(false);
		},
	});

	return <div className='item small' ref={drag} style={{ opacity }} draggable>
		<img className='svg' src={data.img} />
		<span>{data.component.name}</span>
	</div>;
};