import React from 'react';
import { toast } from 'react-toastify';
import { toast_error } from './styles';
import { SpinnerCircularFixed } from 'spinners-react';
import { Link, Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import back from './assets/img/back.png';

function NavCompany({ children, data, setData, isLoading, setIsLoading, base_url }) {
	const { company } = useParams();
	const { id } = useParams();

	React.useEffect(() => {
		checkIsLogged();
	}, [id]);

	const checkIsLogged = async () => {
		setIsLoading(true);
		setData({});

		try {
			let url = `${base_url}?company=${company}`;
			if (id) {
				url = `${base_url}?company=${company}&page=${id}`;
			}

			const res = await fetch(url, {
				method: 'get',
				headers: { 'Content-Type': 'application/json' },
			});

			if (res.status === 200) {
				const response = await res.json();
				setData(response);
				setIsLoading(false);
			} else {
				const response = await res.json();
				toast.error(response.error.details, toast_error);
			}
		} catch (error) {
			toast.error('Récupération des informations impossible.', toast_error);
		}
	};

	if (isLoading) {
		return <div className='body'>
			<header>
				<div className='nav-head'>
					<Link to={`/company/${company}`}>
						<img src={`/image?serv=Logo_lite&company=${company}`} className='icone' />
					</Link>
					<span> {company} </span>
				</div>
				<div className='mr-4'>
					<SpinnerCircularFixed color='#fff' secondaryColor='transparent' size={30} speed={100} thickness={150} />
				</div>
			</header>
			<div className='nav-retour'>
				<Link to={'/index'}>
					<img src={back} className='img-close' style={{maxWidth: 25}}/>
					<span className='retour'> Changez de compagnie </span>
				</Link>
			</div>
			<div className='content'>
				{children}
				<Outlet />
			</div>
		</div>;
	}

	return <div className='body'>
		<header>
			<div className='nav-head'>
				<Link to={`/company/${company}`}>
					<img src={`/image?serv=Logo_lite&company=${company}`} className='icone' />
				</Link>
				<span> {company} </span>
			</div>
			<div className='nav-link'>
				<Link to={`/company/${company}`} className='link_white'>
                    Accueil
				</Link>
				<Link to={`/company/${company}/trafic`} className='link_white'>
                    Trafic
				</Link>
				{
					data.map.is_link &&
                    <a href={data.map.link} className='link_white'>
                        Plan
                    </a>
				}
				{
					data.map.img &&
                    <Link to={`/company/${company}/map`} className='link_white'>
                        Plan
                    </Link>
				}
				{
					data.pages.filter((page) => page.pinned == true).map((page) =>
						<Link to={`/company/${company}/page/${page.id}`} key={page.id} className='link_white'>
							{page.name}
						</Link>
					)
				}
				{
					data.pages.filter((page) => page.pinned == false && page.visibility == true).map((page) =>
						<Link to={`/company/${company}/page/${page.id}`} key={page.id} className='link_white'>
							{page.name}
						</Link>
					)
				}
			</div>
		</header>
		<div className='nav-retour'>
			<Link to={'/index'}>
				<img src={back} className='img-close' style={{maxWidth: 25}}/>
				<span className='retour'> Changez de compagnie </span>
			</Link>
		</div>
		<div className='content'>
			{children}
			<Outlet />
		</div>
	</div>;
}

export default NavCompany;