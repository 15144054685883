import React, { useState } from 'react';
import Toggle from 'react-toggle';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';
import ImageInput from '../helpers/ImageInput';

import check from '../assets/img/check.svg';

function Maps({ data, handleOnChange, updateData }) {
	function handleChangeMapLink(event) {
		const value = event.target.value;
		handleOnChange({ 'map': { 'link': { $set: value } } });
	}

	async function handleChangeMapIsLink(event) {
		setIsLoadingMapIsLink(true);
		const value = event.target.checked;
		const { code, response } = await updateData('map_is_link', value);

		if (code === 200) {
			handleOnChange({ 'map': { 'is-link': { $set: (response['response']['value'] === true) } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingMapIsLink(false);
	}

	async function handleSaveMapLink(event) {
		event.preventDefault();
		event.currentTarget.blur();
        
		setIsLoadingMapLink(true);
		const value = data['map']['link'];
		const { code, response } = await updateData('map_link', value);

		if (code === 200) {
			handleOnChange({ 'map': { 'link': { $set: response['response']['value'] } } });
			setSuccessMapLink(true);
			setTimeout(() => setSuccessMapLink(false), 10000);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingMapLink(false);
	}

	const [isLoadingMapIsLink, setIsLoadingMapIsLink] = useState(false);
	const [isLoadingMapLink, setIsLoadingMapLink] = useState(false);
	const [successMapLink, setSuccessMapLink] = useState(false);

	return (
		<div>
			<div className='block'>
				<h3>Plan de votre compagnie</h3>
				<div className='blue'>
                    Si vous venez de mettre à jour une image, pensez à vider le cache de votre navigateur pour voir les changements. <br />
				</div>
				<div className='orange'>
                    Si vous définisez un lien, assurez vous qu’il commence bien par ’https://’ ou ’http://’
				</div>
				<b className='is-block my-5'>Type de plan</b>
				<form className='fluent_form left'>
					<Toggle
						checked={data['map']['is-link']}
						icons={false}
						id='show_price'
						onChange={handleChangeMapIsLink}
					/>
					{isLoadingMapIsLink
						? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
						: <label htmlFor='visible'>{data['theming']['show_price'] ? 'Lien' : 'Image'}</label>
					}
				</form>
				<br /><br />
				{
					data['map']['is-link'] === true
						? <form className='fluent_form'>
							<input
								onClick={handleChangeMapLink}
								placeholder='Lien de votre carte'
								type='text'
								value={data['map']['link']}
							/>
							<button className=' blue' onClick={handleSaveMapLink}>
								<span>
									{isLoadingMapLink
										? <div>
											<SpinnerCircularFixed color='#ffffff' secondaryColor='transparent' size={20} speed={100} thickness={150} />
										</div>
										: successMapLink
											? <img alt='Enregistré avec succès' className='svg_white check' src={check} />
											: 'Valider'
									}
								</span>
							</button>
						</form>
						: <ImageInput
							canBeNull
							company={data['account']['name']}
							defined={data['map']['img']}
							handleOnChange={handleOnChange}
							id='Map'
							isMap
							label='Plan du réseau de votre compagnie'
							updateData={updateData}
						/>
				}


			</div>
		</div>

	);
}

export default Maps;