const toolbarGroups = [
	[
		{
			id: 1,
			format: 'fontFamily',
			type: 'dropdown',
			options: [
				{ 'value': 'sans', 'label': 'Sans Serif' },
				{ 'value': 'serif', 'label': 'Serif' },
				{ 'value': 'monospace', 'label': 'MonoSpace' },
			]
		},
		{
			id: 2,
			format: 'fontSize',
			type: 'dropdown',
			options: [
				{ 'value': 'small', 'label': 'Petit' },
				{ 'value': 'normal', 'label': 'Normal' },
				{ 'value': 'medium', 'label': 'Moyen' },
				{ 'value': 'huge', 'label': 'Grand' },
			]
		}
	],
	[
		{
			id: 3,
			format: 'bold',
			type: 'mark',
		},
		{
			id: 4,
			format: 'italic',
			type: 'mark',
		},
		{
			id: 5,
			format: 'underline',
			type: 'mark',
		},
		{
			id: 6,
			format: 'strikethrough',
			type: 'mark',
		},
	],
	[
		{
			id: 7,
			format: 'color',
			type: 'color-picker',

		}
	],
	[
		{
			id: 8,
			format: 'superscript',
			type: 'mark',
		},
		{
			id: 9,
			format: 'subscript',
			type: 'mark',
		},
	],
	[
		{
			id: 10,
			format: 'headingOne',
			type: 'block',
		},
		{
			id: 11,
			format: 'headingTwo',
			type: 'block',
		},
		{
			id: 12,
			format: 'headingThree',
			type: 'block',
		},
		{
			id: 13,
			format: 'blockquote',
			type: 'block',
		},
	],
	[
		{
			id: 14,
			format: 'orderedList',
			type: 'block'
		},
		{
			id: 15,
			format: 'unorderedList',
			type: 'block'
		}
	],
	[
		{
			id: 16,
			format: 'alignLeft',
			type: 'block'
		},
		{
			id: 17,
			format: 'alignCenter',
			type: 'block'
		},
		{
			id: 18,
			format: 'alignRight',
			type: 'block'
		},
	],
	[
		{
			id: 19,
			format: 'link',
			type: 'link',
		},
		{
			id: 20,
			type: 'table'
		}
	],
	[
		{
			id: 21,
			type: 'inTable'
		}
	],
];

export default toolbarGroups;