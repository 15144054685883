import React, { useState } from 'react';
import Toggle from 'react-toggle';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';

import Hide from '../helpers/Hide';

import reset from '../assets/img/reset.svg';
import save from '../assets/img/save.svg';
import add from '../assets/img/add.svg';

function Trains({ data, handleOnChange, updateData }) {
	async function handleChangePassenger(event) {
		setIsLoadingPassenger(true);
		const value = event.target.checked;
		const { code, response } = await updateData('show_passenger', value);

		if (code === 200) {
			handleOnChange({ 'trains': { 'display': { 'passenger': { $set: (response['response']['value'] === true) } } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingPassenger(false);
	}
	async function handleChangeFret(event) {
		setIsLoadingFret(true);
		const value = event.target.checked;
		const { code, response } = await updateData('show_fret', value);

		if (code === 200) {
			handleOnChange({ 'trains': { 'display': { 'fret': { $set: (response['response']['value'] === true) } } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingFret(false);
	}
	async function handleChangeInfra(event) {
		setIsLoadingInfra(true);
		const value = event.target.checked;
		const { code, response } = await updateData('show_infra', value);

		if (code === 200) {
			handleOnChange({ 'trains': { 'display': { 'infra': { $set: (response['response']['value'] === true) } } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingInfra(false);
	}

	async function handleSaveRules(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingSaveRules(true);
		const { code, response } = await updateData('set_hide_rules', data.trains.rules);

		if (code === 200) {
			handleOnChange({ 'trains': { 'rules': { $set: response } } });
			toast.success('Enregistré avec succès', toast_error);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingSaveRules(false);
	}
	async function handleGetRules(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingGetRules(true);
		const { code, response } = await updateData('get_hide_rules', '');

		if (code === 200) {
			handleOnChange({ 'trains': { 'rules': { $set: response } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingGetRules(false);
	}

	function handleAddHide(event, type) {
		event.preventDefault();
		event.currentTarget.blur();

		handleOnChange({ 'trains': { 'rules': { [type]: { $push: [''] } } } });
	}
	function handleRemoveHide(event, index, type) {
		event.preventDefault();
		event.currentTarget.blur();
        
		handleOnChange({ 'trains': { 'rules': { [type]: { $splice: [[index, 1]] } } } });
	}
	function handleChangeRule(event, index, type) {
		const value = event.target.value;
		handleOnChange({ 'trains': { 'rules': { [type]: { [index]: { $set: value } } } } });
	}

	const [isLoadingPassenger, setIsLoadingPassenger] = useState(false);
	const [isLoadingFret, setIsLoadingFret] = useState(false);
	const [isLoadingInfra, setIsLoadingInfra] = useState(false);

	const [isLoadingSaveRules, setIsLoadingSaveRules] = useState(false);
	const [isLoadingGetRules, setIsLoadingGetRules] = useState(false);

	return (
		<div>
			<div className='block'>
				<h3> Réglez les détails d’affichage de vos trains</h3>
                Choissisez d’afficher ou de masquer vos trains de Voyageurs, Fret ou d’Infra lors d’une recherche (Itinéraires, Départs, ...).
				<br />
				<br />
				<div className='is-block mb-5'>
                    Voyageur
					<br />
					<br />
					<Toggle
						checked={data['trains']['display']['passenger']}
						icons={false}
						onChange={handleChangePassenger}
					/>
					{isLoadingPassenger
						? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
						: <label htmlFor='visible'>{data['trains']['display']['passenger'] ? 'Affiché' : 'Masqué'}</label>
					}
				</div>
				<div className='is-block mb-5'>
                    Fret
					<br />
					<br />
					<Toggle
						checked={data['trains']['display']['fret']}
						icons={false}
						onChange={handleChangeFret}
					/>
					{isLoadingFret
						? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
						: <label htmlFor='visible'>{data['trains']['display']['fret'] ? 'Affiché' : 'Masqué'}</label>
					}
				</div>
				<div className='is-block mb-5'>
                    Infra
					<br />
					<br />
					<Toggle
						checked={data['trains']['display']['infra']}
						icons={false}
						onChange={handleChangeInfra}
					/>
					{isLoadingInfra
						? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
						: <label htmlFor='visible'>{data['trains']['display']['infra'] ? 'Affiché' : 'Masqué'}</label>
					}
				</div>
			</div>

			<div className='block'>
				<h3> Masquer des trains selon leur nom ou classification </h3>
                Vous pouvez masquer plus précisement des trains selon leur classification ou nom.

				<div className='ribbon'>
					<button className='is-inline-flex' onClick={handleGetRules} style={{ width: '130px' }}>
						{
							isLoadingGetRules
								? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
								: <img className='svg' src={reset} />
						}
						<span>Actualiser</span>
					</button>
					<button className='is-inline-flex' onClick={handleSaveRules} style={{ width: '155px' }}>
						{
							isLoadingSaveRules
								? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
								: <img className='svg' src={save} />
						}
						<span>Sauvegarder</span>
					</button>
					<span className='info' />
				</div>

				<div className='block' >
					<b className='is-block'> Train(s) masqué la classification du train : </b>

					{
						data.trains.rules.class.map((item, i) =>
							<Hide i={i}
								item={item}
								key={i}
								label='Classification'
								onChange={handleChangeRule}
								onRemove={handleRemoveHide}
								type='class'
							/>
						)
					}

					<button className='fluent_btn is-inline-flex ml-5 mt-2 mb-4 left' onClick={(e) => handleAddHide(e, 'class')} style={{ width: 'calc(100% - 36px)' }}>
						<img className='svg' src={add} />
						<span>Ajouter une classification à masquer</span>
					</button>

					<br />
					<b className='is-block'> Train(s) masqué selon leur nom : </b>

					{
						data.trains.rules.name.map((item, i) =>
							<Hide i={i}
								item={item}
								key={i}
								label='Nom'
								onChange={handleChangeRule}
								onRemove={handleRemoveHide}
								type='name'
							/>
						)
					}

					<button className='fluent_btn is-inline-flex ml-5 mt-2 mb-4 left' onClick={(e) => handleAddHide(e, 'name')} style={{ width: 'calc(100% - 36px)' }}>
						<img className='svg' src={add} />
						<span>Ajouter une nom à masquer</span>
					</button>
				</div>
			</div>
		</div>

	);
}
export default Trains;