import React from 'react';

function Loading() {
	return <div id='root'>
		<div className='start' id='start'>
            
			<h1>MyLines</h1>

		</div>
	</div>;
}

export default Loading;