import React, {useState} from 'react';
import Select from 'react-select';

import Log from '../../helpers/admin/Log';

import search from '../../assets/img/search.svg';

function Logs({ data }) {
	function handleOnChangeSearch(event) {
		setSearchValue(event.target.value);
	}

	function handleOnChangeFilter(event) {
		setFilterValue(event.value);
	}

	const levelOptions = [
		{'value': '', 'label': 'Tout'},
		{'value': 0, 'label': 'Élevée'},
		{'value': 1, 'label': 'Importante'},
		{'value': 2, 'label': 'Moyenne'},
		{'value': 3, 'label': 'Basse'},
	];

	const [searchValue, setSearchValue] = useState('');
	const [filterValue, setFilterValue] = useState('');

	const searchedData = data.admin.logs.filter(log =>
		searchValue == log.id || log.title.toLowerCase().includes(searchValue.toLowerCase()) || log.details.toLowerCase().includes(searchValue.toLowerCase())
        || log.account_name.toLowerCase().includes(searchValue.toLowerCase())|| log.ip.toLowerCase().includes(searchValue.toLowerCase()) || log.account == searchValue
	);

	const filteredData = searchedData.filter(log =>
		filterValue == log.level || filterValue === ''
	);

	return <div>
		<div className='block'>
			<div className='is-flex'>
				<div className='is-inline-block mr-3' style={{ width: '25vw' }}>
					<Select
						className='basic-single'
						classNamePrefix='select'
						classNames={{
							control: (state) => state.isFocused ? 'select active' : 'select',
							menu: () => 'select-list',
							singleValue: () => 'select-value',
							option: (state) => `select-option ${state.isSelected ? 'active' : ''}`,
							indicatorSeparator: () => 'select-separator',
						}}
						isSearchable={true}
						name='color'
						noOptionsMessage={() => 'Aucun résultat'}
						onChange={handleOnChangeFilter}
						options={levelOptions}
						placeholder='Filtrer par importance'
					/>
				</div>
				<div className='is-inline-block  mr-3' style={{ width: '25vw' }}>
					<input
						autoFocus
						className='left is-inline-block search'
						onChange={handleOnChangeSearch}
						placeholder='Rechercher'
						type='text'
						value={searchValue}
					/>
				</div>
			</div>
			<p>{filteredData.length} évènement(s).</p>
		</div>
		<table>
			<tbody>
				{
					filteredData.map(item => <Log item={item} key={item.id} />)
				}
			</tbody>
		</table>

	</div >;
}

export default Logs;