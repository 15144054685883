import React from 'react';
import { toast } from 'react-toastify';

import { toast_pending } from '../../styles';

import reset from '../../assets/img/reset.svg';
import admin from '../../assets/img/admin.svg';

function Check({ data, updateData, handleOnChange }) {
	const toastId = React.useRef(null);

	async function handleOnClickCheck(event, req) {
		event.preventDefault();
		event.currentTarget.blur();

		toastId.current = toast('Patientez...', toast_pending);

		const { code, response } = await updateData(req, true);

		if (code === 200) {
			handleOnChange({ 'admin': { 'check': { $set: response['response']['value'] } } });
			toast.update(toastId.current, { render: 'Analyse effectué avec succès.', type: toast.TYPE.SUCCESS, autoClose: 5000 });
		} else {
			toast.update(toastId.current, { render: 'Une erreur s’est produite.', type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	return <div className='block'>
		<h3>Recherche de problèmes et corrections</h3>
		<div>
			<div>Dernière actualisation : {data.admin.check.update.time} par {data.admin.check.update.user}</div>
			<div>Dernière action : {data.admin.check.action.time} par {data.admin.check.action.user}</div>
		</div>
		<div className='block'>
			<button className='is-inline-flex  blue' onClick={(e) => handleOnClickCheck(e, 'admin_check_update')} style={{ width: '135px' }}>
				<img className='svg_white' src={reset} />
				<span>Actualiser</span>
			</button>
			<button className='ml-3 is-inline-flex  red' onClick={(e) => handleOnClickCheck(e, 'admin_check_action')} style={{ width: '95px' }}>
				<img className='svg_white' src={admin} />
				<span>Agir</span>
			</button>
		</div>
		<div className='block' dangerouslySetInnerHTML={{ __html: data.admin.check.result }} />
	</div>;
}

export default Check;