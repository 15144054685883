import React, {useState} from 'react';

import AccountAdmin from '../../helpers/admin/AccountAdmin';

function Accounts({ data, setAccount }) {
	function handleOnChangeSearch(event) {
		setSearchValue(event.target.value);
	}

	const [searchValue, setSearchValue] = useState('');

	const filteredData = data.admin.accounts.filter(account => account.id == searchValue || account.name.toLowerCase().includes(searchValue.toLowerCase()) || account.email.toLowerCase().includes(searchValue.toLowerCase()));

	return <div>
		<div className='block'>
			<input
				autoFocus
				className='left is-inline-block blue search'
				onChange={handleOnChangeSearch}
				placeholder='Rechercher parmis les comptes'
				type='text'
				value={searchValue}
			/>
			<p>{filteredData.length} compte(s).</p>
		</div>
		<div className='block'>
			{
				filteredData.map(account => <AccountAdmin
					account={account}
					key={account.id}
					setAccount={setAccount}
				/>
				)
			}
		</div>
	</div>;
}

export default Accounts;