import React from 'react';
import Icon from '../../common/Icon';
import { TableUtil } from '../../utils/table.js';

const InTable = ({ editor }) => {
	const table = new TableUtil(editor);

	const handleButtonClick = (action) => {
		switch (action) {
		case 'row':
			table.insertRow();
			break;
		case 'column':
			table.insertColumn();
			break;
		case 'remove':
			table.removeTable();
			break;
		default:
			return;
		}
	};
	return (
		<>
			<button className='mini_btn' format='insert row' onClick={() => handleButtonClick('row')}>
				<Icon icon='row' />
			</button>
			<button className='mini_btn' format='insert column' onClick={() => handleButtonClick('column')}>
				<Icon icon='column' />
			</button>
			<button className='mini_btn' format='remove table' onClick={() => handleButtonClick('remove')}>
				<Icon icon='removeTable' />
			</button>
		</>
	);
};

export default InTable;
