import React from 'react';
import { useDrop } from 'react-dnd';

import trash from '../assets/img/trash.svg';

function InfoDelete({ i, handleDelete }) {
	const [, drop] = useDrop({
		accept: `info${i}`,
		drop: (item) => {
			handleDelete(item);
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		canDrop: () => true,
	});

	return <a
		className='btn red  info-delete is-inline-flex'
		ref={drop}
	>
		<img className='svg_white' src={trash} />
		<span>Supprimer</span>
	</a>;
}

export default InfoDelete;