import React from 'react';
import { Link } from 'react-router-dom';

import ImageInput from '../helpers/ImageInput';

import train from '../assets/img/train.svg';
import property from '../assets/img/property.svg';


function Images({ data, handleOnChange, updateData }) {
	return (
		<div>
			<Link to='/settings/images/class' className='is-block box' style={{ border: '2px solid #868686' }} >
				<img className='himg svg' src={train}/> <b>Images de classification</b>
				<br />
                Afficher toutes les images de votre compte.
			</Link>
			<Link to='/settings/images/embed' className='is-block box' style={{ border: '2px solid #868686' }} >
				<img className='himg svg' src={property}/> <b>Images des infogares</b>
				<br />
                Afficher toutes les images de votre compte.
			</Link>

			{/* <a href="#" onclick="Show('logo_page')" class="settings-account" style="border: 2px solid #868686; display: block;">
  <div>
      <img src="assets/img/page.svg" class="himg svg" style="top: 5px;"> <b style="position: relative;top: -7px;">Images des pages</b>
          <br>
      Afficher toutes les images de votre compte.
  </div>
    </a> */}
			<br />
			<h3>Images de votre compagnie</h3>
            Modifiez ici les images qui définissent au mieux votre compagnie<br />
			<div className='orange'>
                Vous devez avoir les droits sur les images que vous mettez ici. Les logos d’opérateurs ferroviaires réel sont strictement interdits !
			</div>
			<ImageInput
				company={data['account']['name']}
				defined={data['images']['Logo']}
				handleOnChange={handleOnChange}
				id='Logo'
				label='Logo de votre compagnie'
				updateData={updateData}
			/>
			<ImageInput
				company={data['account']['name']}
				defined={data['images']['Logo_lite']}
				handleOnChange={handleOnChange}
				id='Logo_lite'
				label='Petit logo de votre compagnie'
				updateData={updateData}
			/>
			<ImageInput
				company={data['account']['name']}
				defined={data['images']['Wallpaper']}
				handleOnChange={handleOnChange}
				id='Wallpaper'
				label='Logo de votre compagnie'
				updateData={updateData}
			/>
		</div>
	);
}

export default Images;