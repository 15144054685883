import React, { useState } from 'react';

function LimitedInput({ onChange, ...props }) {
	if (!props.maxLength) {
		throw new Error('maxLength is not defined');
	}

	const [lengthString, setLengthString] = useState(0);
	const [visible, setVisible] = useState(false);

	function handleOnChange(event) {
		setLengthString(event.target.value.length);
		setVisible(true);
		onChange(event.target.value = event.target.value.slice(0, props.maxLength));
	}

	return (

		<>
			{
				props.istextarea
					? <textarea
						onChange={handleOnChange}
						{...props}
					/>
					: <input
						onChange={handleOnChange}
						{...props}
					/>
			}
			{
				visible && !props.hideCounter && <div className={`right ${lengthString === props.maxLength && 'is-red rumble'}`}>
					{lengthString} / {props.maxLength}
				</div>
			}


		</>
	);
}

export default LimitedInput;