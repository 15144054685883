import React, { useState } from 'react';
import InfoTraficBlock from './Trafic';
import down from '../../assets/img/down.png';

function parseTime(timeString) {
	const parts = timeString.split(':');
	console.log(timeString);

	if (parts.length === 2) {
		const hours = parseInt(parts[0]);
		const minutes = parseInt(parts[1]);


		console.log(hours);
		console.log(minutes);

		var time = new Date();
		time.setHours(hours);
		time.setMinutes(minutes);
		time.setSeconds(0);

		return time;
	}
	return null;
}
function addMinutesToDate(date, minutes) {
	var time = new Date(date.getTime() + minutes * 60000);
	return `${addZero(time.getHours())}:${addZero(time.getMinutes())}`;
}
function getService(service, company) {
	return `url('/image?serv=${service}&company=${company}')`;
}
function addZero(i) {
	if (i < 10) { i = '0' + i; }
	return i;
}

function getState(arrival) {
	if (arrival.is_deleted) {
		return 'deleted';
	} else if (arrival.is_delayed) {
		return 'delayed';
	} else if (arrival.is_before) {
		return 'before';
	}
	return '';
}

function TrainArrival({ company, arrival }) {

	const [show, setShow] = useState(false);

	const stops = arrival.intermediate.split(';');
	console.log(stops);

	return <div className={`transition depart ${getState(arrival)} ${show && 'focused'}`}>
		<table>
			<tbody>
				<tr>
					<td style={{ backgroundImage: getService(arrival.class, company) }} className='class'></td>
					{
						arrival.arrival != null && <>
							{
								arrival.is_delayed || arrival.is_before
									? <>
										<td className='time'> {addMinutesToDate(parseTime(arrival.arrival), arrival.delay)} </td>
										<td className='old_time'> {arrival.arrival} </td>
									</>
									: <td className='time'>{arrival.arrival}</td>
							}

							<td className='dest'>{arrival.from}</td>
						</>
					}
				</tr>
			</tbody>
		</table>
		<div className='is-flex is-relative is-align-items-center pb-3' style={{ left: 45, cursor: 'pointer' }} onClick={() => setShow(!show)}>
			<img src={down} className='transition details-img img-dw' style={{ transform: show ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
			<b style={{ color: 'var(--blue-primary)' }}>Afficher les details</b>
		</div>
		<form className='fluent_form left '>
			<InfoTraficBlock
				info={arrival.disruptions}
				show={show}
			/>
			{
				show && <>
					<h3>{arrival.name}</h3>
					<div>
						Train {arrival.class} n°<b>{arrival.id}</b>
					</div>
					<br />
					{
						stops[0] == 'direct' && <b>Train direct</b>
					}
					<div className='desserte'>
						<span className='stop' style={{height: 21}}>
							<span className='stop-dots' style={{left: 0}}></span>
							<b>
								<a className='link' style={{left: 3}}>{arrival.from}</a>
							</b>
						</span>
						{
							stops[0] !== 'direct' && stops.map(stop => <span key={stop} className='stop'>
								<span className='stop-line'></span>
								<span className='stop-dots'></span>
									<a className='link'>{stop}</a>
							</span>)
						}
						<span className='stop'>
							<span className='stop-line'></span>
							<span className='stop-dots'></span>
							<b>
								<a className='link'>{arrival.to}</a>
							</b>
						</span>
					</div>
				</>
			}
		</form>
	</div>;
}

export default TrainArrival;