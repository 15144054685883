import React, { useRef, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import LimitedInput from './LimitedInput';
import InfoBlock from './InfoBlock';
import InfoDelete from './InfoDelete';

import trash from '../assets/img/trash.svg';
import grip from '../assets/img/grip.svg';
import add from '../assets/img/add.svg';

const ItemTypes = {
	CARD: 'card',
};

function DisruptionBlock({ data, id, element, index, handleChangeClass, onRemove, moveCard, moveInfo, handleDelete, setModal }) {
	const ref = useRef(null);
	const [{ handlerId }, drop] = useDrop({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			const clientOffset = monitor.getClientOffset();

			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveCard(dragIndex, hoverIndex);

			item.index = hoverIndex;
		},
	});
	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			return { id, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	const opacity = isDragging ? 0.5 : 1;
	drag(drop(ref));

	const [isMoving, setIsMoving] = useState(false);

	return <div className='box p-0' data-handler-id={handlerId} ref={ref} style={{ opacity }}>
		<img className='svg ml-0 drag-grip' src={grip} />
		<LimitedInput
			className='is-inline-block ml-2'
			hideCounter
			maxLength={50}
			onChange={(v) => handleChangeClass(v, index, 'title')}
			placeholder='Catégorie'
			style={{ width: 'calc(100% - 95px)' }}
			type='text'
			value={element.title}
		/>
		<button
			className='is-inline-flex is-relative red'
			onClick={(e) => onRemove(e, index)}
			style={{ width: 46, height: 46, top: 8 }}
		>
			<img className='svg_white ml-0' src={trash} />
		</button>

		<div className='ml-5 mb-5 is-flex is-flex-wrap'>
			<DndProvider backend={HTML5Backend}>
				{
					element.elements.map((e, index) => <InfoBlock
						data={data}
						e={e}
						i={id}
						index={index}
						key={e}
						moveInfo={(dragIndex, hoverIndex) => moveInfo(dragIndex, hoverIndex, id)}
						setIsMoving={setIsMoving}
					/>
					)
				}
				{
					isMoving && <InfoDelete
						handleDelete={item => handleDelete(item, id)}
						i={id}
						key='delete'
					/>
				}
			</DndProvider>

			<button onClick={() => setModal(id)} style={{ width: 83, height: 83, margin: 10 }}>
				<img alt='Fermer' className='svg' src={add} />
			</button>
		</div>
		<span className='space'></span>
	</div>;
}

export default DisruptionBlock;