import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';
import Lottie from 'lottie-react';

import loading from '../assets/animation/loading.json';

import ContentComponement from './editpage/ContentComponement';
import TrainArrival from './train/Arrival';

import { wallpaper } from '../styles';
import down from '../assets/img/down.png';
import update from '../assets/img/update.png';

function Arrival({ data }) {
	const { stop } = useParams();
	const { company } = useParams();

	if (data.account == null) {
		return <>
			<div className='Wallpaper' style={wallpaper(company)} />
			<div className='center'>
				<SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={50} speed={100} thickness={150} />
			</div>
		</>;
	}

	const [state, setState] = useState(true);
	const [showEmbed, setShowEmbed] = useState(false);
	const [embedType, setEmbedType] = useState('SNCF/arrival');
	const [trains, setTrains] = useState(null);
	const [trainError, setTrainError] = useState(null);

	useEffect(() => {
		getDeparture();
	}, []);

	const getDeparture = async () => {
		setState('pending');

		try {
			const res = await fetch(`https://beta.hackernwar.com/arrivals?company=${company}&from=${stop}`, {
				method: 'get',
				headers: { 'Content-Type': 'application/json' },
			});

			if (res.status === 200) {
				const response = await res.json();
				setTrains(response);
				setState('success');
				setTrainError(null);
			} else {
				const response = await res.json();
				toast.error(response.error.details, toast_error);
				setState('error');
				setTrainError(response.error.details);
			}
		} catch (error) {
			toast.error('Récupération des informations impossible.', toast_error);
			setState('error');
			setTrainError('Récupération des informations impossible.');
		}
	};

	function handleOnClickRadio(event) {
		setEmbedType(event.target.value);
	}


	return <div className='body'>
		<div className='Wallpaper' style={wallpaper(company)} />

		<div className='row row2 ml-5 pl-5 py-5 my-5'>
			<div className='ml-5 pl-5 py-5 my-5'>
				<h1 className='index-title' style={{ fontSize: '3.5em' }}>
					{data.account.name}
				</h1>
				<h3 className='index-title'>
					{data.account.description}
				</h3>
			</div>
		</div>

		<div className='row row1' style={{ backgroundColor: 'var(--body)' }}>
			<ContentComponement
				component={{
					'type': 'search',
					'content': 'arrivals'
				}}
				data={data}
				disabled={true}
				id={'search_main'}
				setComponents={() => null}
			/>
		</div>

		<br />

		<div className='row row1' style={{ backgroundColor: 'var(--body)' }}>
			<div className='pb-2' style={{ cursor: 'pointer' }} onClick={() => setShowEmbed(!showEmbed)}>
				<img src={down} className='himg transition' style={{ transform: showEmbed ? 'rotate(0deg)' : 'rotate(-90deg)' }} />
				<h2 className='is-inline ml-2' style={{ color: 'var(--blue-primary)' }}>Tableaux des arrivées</h2>
			</div>
			{
				showEmbed &&
				<div className='is-flex space-around'>
					<iframe
						style={{ width: '100%', height: '100%', borderRadius: 10 }}
						className='embed'
						src={`https://mylines.fr/embed/${embedType}/${stop}/${data.account.name}?update=0`}
					/>
					<div className='scrollable mr-5' style={{ minWidth: 250 }}>
						<div className='cc-selector'>
							<input type='radio' id='SNCFa' name='opt' value='SNCF/arrival' defaultValue onClick={handleOnClickRadio} />
							<label className='selectcard-cc SNCFa-card' htmlFor='SNCFa' />
							<br /><br />
							<input type='radio' id='IENAa' name='opt' value='IENA/arrival' onClick={handleOnClickRadio} />
							<label className='selectcard-cc IENAa-card' htmlFor='IENAa' />
							<br /><br />
							<input type='radio' id='TALOSa' name='opt' value='TALOS/arrival' onClick={handleOnClickRadio} />
							<label className='selectcard-cc TALOSa-card' htmlFor='TALOSa' />
						</div>
					</div>
				</div>
			}
		</div>

		<br />

		{
			state == 'error' && <div className='row row1' style={{ backgroundColor: 'var(--red-primary)' }}>
				<b style={{ color: '#fff' }}>{trainError}</b>
			</div>
		}

		{
			state == 'pending' && <div className='row row1' style={{ backgroundColor: 'var(--body)' }}>
				<div className='center is-flex is-justify-content-center'>
					<div style={{ maxWidth: '50%' }}>
						<Lottie animationData={loading} />
						<h3 style={{ color: 'var(--blue-primary)' }}>
							A la recherche de vos trains !
						</h3>
						<span style={{ color: 'var(--blue-primary)' }}>
							Chargement...
						</span>
					</div>
				</div>
			</div>
		}

		{
			state == 'success' && <div className='row row1' style={{ backgroundColor: 'var(--body)' }}>
				<div className='is-flex space-between'>
					<h3 className='ml-5 mb-0' style={{ color: 'var(--blue-primary)' }}>Arrivées à {trains.stop_name}</h3>
					<button className='mini_btn' onClick={() => getDeparture()}>
						<img src={update} />
					</button>
				</div>
				<div className='separateur'></div>

				{
					trains.arrival[0].is_tomorow && <>

						<h3 className='mb-0 ml-5 pl-5' style={{ color: 'var(--blue-primary)' }}>Il n’y a pas de trajet possible ce jour</h3>
						<br />
						<h3 className='ml-5' style={{ color: 'var(--blue-primary)' }}>Trajet du lendemain</h3>

						<div className='separateur'></div>
					</>
				}

				{
					trains.arrival.map(arrival => <TrainArrival
						key={arrival.id}
						data={data}
						company={company}
						arrival={arrival}
					/>
					)
				}

			</div>
		}


	</div >;
}

export default Arrival;