import React from 'react';

import error_white from '../../assets/img/trafic/error_white.png';
import information_white from '../../assets/img/trafic/information_white.png';
import interogation_white from '../../assets/img/trafic/interogation_white.png';
import warning_white from '../../assets/img/trafic/warning_white.png';
import work_white from '../../assets/img/trafic/work_white.png';

const InfoTraficBlock = ({ info, show }) => {
	const x = 0;

	if (info.length === 0) {
		return null;
	}

	function getColorByStatus(status) {
		switch (status) {
		case 4:
			return '#eb2031';
		case 3:
			return '#ffc106';
		case 2:
			return '#ff7106';
		case 1:
			return '#005bbc';
		default:
			return '#a9a9a9';
		}
	}
	function getIconByStatus(status) {
		switch (status) {
		case 4:
			return error_white;
		case 3:
			return warning_white;
		case 2:
			return work_white;
		case 1:
			return information_white;
		default:
			return interogation_white;
		}
	}

	const renderInfoBlock = (x, show) => {
		const y = info.length;
		return <>
			<div className='info-trafic-block mb-3'>

				<div className='info-title-show' style={{ backgroundColor: getColorByStatus(info[x].status) }}>
					<div className='is-flex is-align-items-center'>
						<img src={getIconByStatus(info[x].status)} />
						<b className='is-white ml-2'>{info[x].title}</b>
					</div>
					{(x == 0 && y - 1 > 0 && !show) &&
                        <span className='is-white ml-5'>
                            et {y - 1} autre(s)
                        </span>
					}
				</div>
				{
					show && <>
						<div className='m-2'>
							<span>{info[x].details}</span>
						</div>
						<div className='is-flex is-align-items-center' style={{ backgroundColor: getColorByStatus(info[x].status), height: 2 }}>
						</div>
					</>
				}
			</div>
			{show && y - 1 > x && renderInfoBlock(x + 1, show)}
		</>
		;
	};

	return renderInfoBlock(x, show);
};

export default InfoTraficBlock;
