import React, { useState } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';

import LimitedInput from '../helpers/LimitedInput';
import Checkbox from '../helpers/Checkbox';

import trash from '../assets/img/trash.svg';
import error_white from '../assets/img/trafic/error_white.png';
import information_white from '../assets/img/trafic/information_white.png';
import interogation_white from '../assets/img/trafic/interogation_white.png';
import warning_white from '../assets/img/trafic/warning_white.png';
import work_white from '../assets/img/trafic/work_white.png';

function Info({ item, i, label, type, onChange, onRemove }) {
	function getColorByStatus(status) {
		switch (status) {
		case 'error':
			return '#eb2031';
		case 'warning':
			return '#ffc106';
		case 'work':
			return '#ff7106';
		case 'info':
			return '#005bbc';
		default:
			return '#a9a9a9';
		}
	}
	function getIconByStatus(status) {
		switch (status) {
		case 'error':
			return error_white;
		case 'warning':
			return warning_white;
		case 'work':
			return work_white;
		case 'info':
			return information_white;
		default:
			return interogation_white;
		}
	}
	function handleOnChange(value, index, info, type) {
		onChange(value, index, info, type);
		setIsFocused(false);
	}
	function handleOnChangeExpiration(e) {
		const value = moment(e._d);
		onChange(value, i, 'expiration', type);
	}
	function handleDivBlur() {
		setIsFocused(false);
	}

	function handleDivFocus(event) {
		event.preventDefault();
		event.currentTarget.blur();
		setIsFocused(true);
	}

	function handleMouseEnter(status) {
		setStatusColor(getColorByStatus(status));
	}
	function handleMouseLeave() {
		setStatusColor('grey');
	}


	const info_state = [
		'info',
		'work',
		'warning',
		'error',
	];
	const [isFocused, setIsFocused] = useState(false);
	const [statusColor, setStatusColor] = useState('grey');

	return <div className='Info' key={i}>
        ➜
		<LimitedInput
			className='is-inline-block ml-2'
			hideCounter
			maxLength={50}
			onChange={(v) => handleOnChange(v, i, 'name', type)}
			placeholder={label}
			style={{ width: 'calc(100% - 95px)' }}
			type='text'
			value={item.name}
		/>
		<button
			className='is-inline-flex is-relative  red'
			onClick={(e) => onRemove(e, i, type)}
			style={{ width: '50px', top: '7px' }}
		>
			<img className='svg_white ml-0' src={trash} />
		</button>
		<form className='is-block fluent_form'>
			<div
				className='is-flex is-align-items-center info-title'
				style={{ backgroundColor: getColorByStatus(item.status) }}
			>
				<button
					className='is-inline-flex is-relative '
					onBlur={handleDivBlur}
					onClick={handleDivFocus}
					onFocus={handleDivFocus}
					style={{ width: '50px' }}
					tabIndex={0}
				>
					<img className='ml-0' src={getIconByStatus(item.status)} />
				</button>
				<LimitedInput
					className='is-white p-0 mr-2'
					hideCounter
					maxLength={50}
					onChange={(e) => handleOnChange(e, i, 'title', type)}
					placeholder='Titre'
					type='text'
					value={item.title}
				/>
			</div>
			{
				isFocused && <div
					className='info-change-type'
					onMouseDown={event => event.preventDefault()}
					style={{ backgroundColor: statusColor }}
				>
					{
						info_state.map((state, index) => <button
							className='is-inline-flex is-relative '
							key={index}
							onClick={() => handleOnChange(state, i, 'status', type)}
							onMouseEnter={() => handleMouseEnter(state)}
							onMouseLeave={handleMouseLeave}
							style={{ width: '50px' }}
						>
							<img className='ml-0' src={getIconByStatus(state)} />
						</button>
						)
					}
				</div>
			}
			<div className='m-2'>
				<LimitedInput
					istextarea
					maxLength={500}
					onChange={(value) => handleOnChange(value, i, 'details', type)}
					placeholder='Détails'
					type='text'
					value={item.details}
				/>
			</div>

			<div
				className='is-flex is-align-items-center'
				style={{ backgroundColor: getColorByStatus(item.status), height: '2px' }}
			></div>

			<br />

			<div className='left'>
				<Checkbox
					checked={item['has_expiration']}
					className='is-inline-block'
					label={
						<span>Expire le : </span>
					}
					onChange={(value) => handleOnChange(value, i, 'has_expiration', type)}
				/>
				<div className='is-inline-block ml-4'>
					<Datetime
						className='m-0'
						disabled={!item['has_expiration']}
						isValidDate={valid}
						locale='fr'
						onChange={handleOnChangeExpiration}
						placeholder='s'
						value={item['expiration']}
					/>
				</div>

				<br />

				<Checkbox
					checked={item['is_delayed']}
					className='is-inline-block'
					disabled={item['is_deleted']}
					label='Afficher comme étant retardé'
					onChange={(value) => handleOnChange(value, i, 'is_delayed', type)}
				/>
				<div className='is-inline-block ml-4' style={{ width: '113px' }}>
					<input
						className='m-0'
						min={0}
						onChange={(v) => handleOnChange(v.target.value, i, 'delay', type)}
						type='number'
						value={item['delay']}
					/>
				</div>
				<Checkbox
					checked={item['is_deleted']}
					className='mt-4'
					disabled={item['is_delayed']}
					label='Afficher comme étant supprimé'
					onChange={(value) => handleOnChange(value, i, 'is_deleted', type)}
				/>
			</div>
		</form>
	</div>;
}

var valid = (current) => {
	return current.isAfter(new Date()) || current.isSame(new Date(), 'day');
};

export default Info;