import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';

import { toast_error } from '../styles';
import link_t from '../assets/img/link_t.png';

import check from '../assets/img/check.svg';
import trash from '../assets/img/trash.svg';
import LimitedInput from '../helpers/LimitedInput';

function Account({ data, base_url, handleOnChange, updateData }) {
	function handleChangeApi(event) {
		const value = event.target.value;
		handleOnChange({ 'account': { 'api_key': { $set: value } } });
	}

	function handleChangeName(value) {
		if (/[\\"\\/|&=]/.test(value)) {
			setDisabled(true);
			setMessage('Le nom d’un compte ne peut contenir les caractères " \\  /  | = et &');
		} else {
			setDisabled(false);
			setMessage('');
		}
		setName(value);
	}

	async function handleSaveApiKey(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingApi(true);
		const value = data['account']['api_key'];
		const { code, response } = await updateData('api_key', value);

		if (code === 200) {
			handleOnChange({ 'account': { 'api_key': { $set: response['response']['value'] } } });
			setApiSuccess(true);
			setTimeout(() => setApiSuccess(false), 10000);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingApi(false);
	}

	const [isLoadingApi, setIsLoadingApi] = useState(false);
	const [apiSuccess, setApiSuccess] = useState(false);
	const [name, setName] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [message, setMessage] = useState('');

	return (
		<div>
			{
				data['account']['linked'] === true
					? <div className='green'>
						<img className='svg' src={link_t} /> <b>Votre compte est lié avec Train Empire.</b>
					</div>
					: <div className='orange is-align-items-start'>
						<img className='svg' src={link_t} />
						<div>
							<b>Votre compte n’est pas lié avec Train Empire.</b>
							<br />
                            Vous ne pourrez pas utiliser la recherche de trajet ou les infogares.
							<br />
                            ➜ <u>
								<Link to={'/settings/account'}>
                                    En savoir plus
								</Link>
							</u>
						</div>
					</div>
			}

			<div className='block'>
				<h3>Lien avec Train Empire</h3>
                Cette clé vous permet d’afficher les prochains trains, départs ou arrivées de votre compagnie. <br />

                Cette clé est nécessaire pour rendre votre compagnie publique.
				<br /><br />
				<form className='fluent_form'>
					<input
						defaultValue={data['account']['api_key']}
						onChange={handleChangeApi}
						placeholder='Clé API Train Empire'
						type='text'
					/>
					<button className=' blue' onClick={handleSaveApiKey}>
						<span>
							{isLoadingApi
								? <div>
									<SpinnerCircularFixed color='#ffffff' secondaryColor='transparent' size={20} speed={100} thickness={150} />
								</div>
								: apiSuccess
									? <img alt='Enregistré avec succès' className='svg_white check' src={check} />
									: 'Valider'
							}
						</span>
					</button>
					<div className='blue is-flex-direction-column my-2 mx-0'>
						<b className='is-block'>Comment trouver cette clé ?</b>
                        ➜ Une fois connecté à votre compte Train Empire, copier votre clé API que vous trouverez dans la rubrique ’Mon Compte’ <br />
					</div>
				</form>
			</div>
			<div className='block'>
				<h3>Renommer votre compagnie</h3>
				<form action={base_url} className='fluent_form' method='post'>
					{
						message !== '' &&
                        <div className='is-red'>{message}</div>
					}
					<LimitedInput
						maxLength={disabled ? name.length : 50}
						name='new_name'
						onChange={handleChangeName}
						placeholder='Nouveau nom'
						type='text'
						value={name}
					/>
					<button className=' blue' disabled={disabled} type='submit'>
						<span >Renommer votre compagnie</span>
					</button>
					<div className='orange is-flex-direction-column my-2 mx-0'>
                        Il est interdit d’utiliser entièrement ou partiellement un nom d’opérateur ferroviaire déjà existant. <br />
                        Les noms tels que SNCF, SNCB ou RENFE sont interdit ! Ils ne vous appartiennent pas.
					</div>
				</form>
			</div>
			<div className='block'>
				<h3>Adresse mail</h3>
                Afin de vous contacter, un mail est nécessaire. Assurez-vous qu’il soit correct. <br /><br />

				<form className='fluent_form'>
					<input defaultValue={data['account']['email']} disabled placeholder='Modifier votre mail' type='text' />
					{
						data['account']['g_sign'] === true
							? <div className='blue'>
                                Votre mail est défini par votre compte Google et ne peut être changé.
								<br />
                                Si toute fois cette adresse venait à être fausse, contactez l’administration.
							</div>
							: <div className='blue'>
                                La modification de votre adresse mail n’est pas possible.
								<br />
                                Si toute fois cette adresse venait à être fausse, contactez l’administration.
							</div>
					}
				</form>

			</div>
			<span className='space'></span>
			<div className='block'>
				<div className='orange is-flex-direction-column is-align-items-start ml-2'>
					<u>
						<h3>Vous entrez en zone dangereuse !</h3>
					</u>
					<b className='is-block'>Supprimer votre compte. </b>
                    En supprimant votre compte, l’accès à votre compte ainsi que l’entièreté des données liée à votre compte seront supprimée.
					<b className='is-block'> CETTE ACTION EST IRREVERSIBLE ! </b>
					<Link to='/del_account' className='btn mt-4 red left' style={{ textAlign: 'left' }}>
						<img className='svg_white' src={trash} />
						<span>Supprimer votre compte</span>
					</Link>
				</div>
			</div>

		</div>

	);
}

export default Account;