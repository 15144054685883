/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SpinnerCircularFixed } from 'spinners-react';
import { useParams } from 'react-router-dom';

import Row from './editpage/Row';

import { wallpaper } from '../styles';

function TraficCompany({ data }) {
	const { company } = useParams();

	if (data.page == null) {
		return <>
			<div className='Wallpaper' style={wallpaper(company)} />
			<div className='center'>
				<SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={50} speed={100} thickness={150} />
			</div>
		</>;
	}

	return <div className='body'>
		<div className='Wallpaper' style={wallpaper(company)} />

		<div className='row row2 ml-5 pl-5 py-5 my-5'>
			<div className='ml-5 pl-5 py-5 my-5'>
				<h1 className='index-title' style={{ fontSize: '3.5em' }}>
					{data.account.name}
				</h1>
				<h3 className='index-title'>
					{data.account.description}
				</h3>
			</div>
		</div><br />
	</div>;
}

export default TraficCompany;