import React, { useState } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';

import { toast_error } from '../../styles';

import key from '../../assets/img/key.svg';

function SignupKey({ data, base_url, updateData, handleOnChange }) {

	function handleOnChangeEmail(e) {
		setEmail(e.target.value);
	}

	const getCode = async (event) => {
		event.preventDefault();
		event.currentTarget.blur();
		try {
			setIsLoading(true);
			const res = await fetch(base_url, {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					'admin_get_mail_code': email,
				})
			});
			setIsLoading(false);
			if (res.status === 404) {
				setErrorResponse(true);
			}
			else if (res.status != 200) {
				toast.error('Une erreur s’est produite, impossible de communiquer avec le serveur.', toast_error);
			}
			else {
				const response = await res.json();
				setResponse(response);
				setErrorResponse(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			toast.error('Une erreur s’est produite, impossible de communiquer avec le serveur.', toast_error);
		}
	};

	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [response, setResponse] = useState('');
	const [errorResponse, setErrorResponse] = useState(true);

	return <div className='block'>
		<h3>Obtenir un lien de vérification</h3>
		<form className='fluent_form'>
			<input
				onChange={handleOnChangeEmail}
				placeholder='Adresse email'
				type='text'
				value={email}
			/>
			<button className=' blue' onClick={getCode} type='submit'>
				{isLoading
					? <div>
						<SpinnerCircularFixed color='#ffffff' secondaryColor='transparent' size={20} speed={100} thickness={150} />
					</div>
					: <>
						<img className='svg_white' src={key} />
						<span >Obtenir le code</span>
					</>
				}
			</button>
            
			{
				errorResponse
					? <div></div>
					: response != ''
						? <div></div>
						: null

			}
		</form>
	</div>;
}

export default SignupKey;