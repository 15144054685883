import React, { useState } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';
import LimitedInput from '../helpers/LimitedInput';
import { CirclePicker } from 'react-color';
import { colors } from '../defaultColors';
import { toast_error } from '../styles';
import check from '../assets/img/check.svg';

function Personalization({ data, handleOnChange, updateData }) {
	function handleChangeDescription(value) {
		handleOnChange({ 'account': { 'description': { $set: value } } });
	}
	function handleChangeAlert(value) {
		handleOnChange({ 'account': { 'alert': { 'alert': { $set: value } } } });
	}
	function handleChangeColor(color) {
		handleOnChange({ 'account': { 'alert': { 'alert_color': { $set: color['hex'] } } } });
	}


	async function handleSaveDescription(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingDescription(true);
		const value = data['account']['description'];
		const { code, response } = await updateData('description', value);

		if (code === 200) {
			handleOnChange({ 'account': { 'description': { $set: response['response']['value'] } } });
			setDescriptionSuccess(true);
			setTimeout(() => setDescriptionSuccess(false), 10000);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingDescription(false);
	}
	async function handleSaveAlert(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingAlert(true);
		const value = data['account']['alert'];
		const { code, response } = await updateData('alert', value);

		if (code === 200) {
			handleOnChange({ 'account': { 'alert': { $set: response['response']['value'] } } });
			setAlertSuccess(true);
			setTimeout(() => setAlertSuccess(false), 10000);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingAlert(false);
	}

	const [isLoadingDescription, setIsLoadingDescription] = useState(false);
	const [isLoadingAlert, setIsLoadingAlert] = useState(false);

	const [descriptionSuccess, setDescriptionSuccess] = useState(false);
	const [alertSuccess, setAlertSuccess] = useState(false);

	return (
		<div>
			<div className='block'>
				<h3>Description ou slogan de votre compagnie</h3>
                Donnez envie de voyager 😉
				<form className='fluent_form'>
					<LimitedInput
						istextarea
						maxLength={500}
						onChange={handleChangeDescription}
						placeholder='Une petite description'
						type='text'
						value={data['account']['description']}
					/>

					<button className=' blue' onClick={handleSaveDescription}>
						<span >
							{isLoadingDescription
								? <div>
									<SpinnerCircularFixed color='#ffffff' secondaryColor='transparent' size={20} speed={100} thickness={150} />
								</div>
								: descriptionSuccess
									? <img alt='Enregistré avec succès' className='svg_white check' src={check} />
									: 'Valider'
							}
						</span>
					</button>
				</form>
			</div>
			<div className='block'>
				<h3>Bandeau d’alerte sur les pages de votre compagnie</h3>
                Idéal pour informer rapidement en cas de perturbation majeures. <br />
                Laissez vide pour désactiver. <br /><br />
				<form className='fluent_form'>

					<LimitedInput
						istextarea
						maxLength={500}
						onChange={handleChangeAlert}
						placeholder="Que ce passe t'il ?"
						type='text'
						value={data['account']['alert']['alert']}
					/>

					<button className=' blue' onClick={handleSaveAlert}>
						<span >
							{isLoadingAlert
								? <div>
									<SpinnerCircularFixed color='#ffffff' secondaryColor='transparent' size={20} speed={100} thickness={150} />
								</div>
								: alertSuccess
									? <img alt='Enregistré avec succès' className='svg_white check' src={check} />
									: 'Valider'
							}
						</span>
					</button>

					<CirclePicker circleSize={25}
						width={350}
						circleSpacing={10}
						className='mt-4'
						color={data['account']['alert']['alert_color']}
						colors={colors}
						onChange={handleChangeColor}
					/>
				</form>

			</div>
		</div>
	);
}

export default Personalization;