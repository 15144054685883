import React from 'react';

import trash from '../assets/img/trash.svg';

function Hide({ item, i, label, type, onChange, onRemove }) {
	return <div className='hide' key={i}>
➜
		<input
			className='is-inline-block ml-2'
			maxLength={50}
			onChange={(e) => onChange(e, i, type)}
			placeholder={label}
			style={{ width: 'calc(100% - 95px)' }}
			type='text'
			value={item}
		/>
		<button
			className='is-inline-block is-relative  red'
			onClick={(e) => onRemove(e, i, type)}
			style={{ width: '50px', top: '7px' }}
		>
			<img className='svg_white ml-0' src={trash} />
		</button>
	</div>;
}

export default Hide;