/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import update from 'react-addons-update';

import del from '../assets/img/delete.svg';
import add from '../assets/img/add.svg';
import check from '../assets/img/check.svg';
import save from '../assets/img/save.png';

import LimitedInput from '../helpers/LimitedInput';
import EditImgUpload from '../helpers/EditImgUpload';

// eslint-disable-next-line react/prop-types, no-unused-vars
function EditModal({ data, handleOnChange, updateData, modal, setModal, modalComponement, setComponents, layout, removeItemFromLayout }) {

	const [value, setValue] = useState('');
	const [message, setMessage] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const [selectedStop, setSelectedStop] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [stops, setStops] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const list = data.logos.class;
	const filteredData = list.filter(item => item.toLowerCase().includes(value.toLowerCase()));

	function handleOnChangeValue(event) {
		setValue(event.target.value);
	}

	function handleOnChangeIframe(event) {
		const regex = /<iframe.*<\/iframe>/i;
		if (regex.test(event)) {
			setMessage(null);
		} else {
			setMessage('L’intégration est invalide.');
		}
		setValue(event);
	}

	function handleSetValue(value) {
		setComponents(components => update(components, { [modalComponement]: { 'content': { $set: value } } }));
		setValue('');
		setMessage(true);
		setSelectedStop(null);
		setSelectedType(null);
		setStops([]);
		setIsLoading(false);
		setModal(null);
	}

	function handleCancel() {
		removeItemFromLayout(modalComponement);
		setValue('');
		setMessage(true);
		setSelectedStop(null);
		setSelectedType(null);
		setStops([]);
		setIsLoading(false);
		setModal(null);
	}

	function handleOnClickRadio(event) {
		setSelectedType(event.target.value);
	}

	function getStops(event) {
		setIsLoading(true);
		setValue(event.target.value);

		let url = `https://api.mylines.fr/te/search?auth=${data.account.api_key}&q=${event.target.value}`;

		fetch(url, {
			method: 'get'
		})
			.then(res => res.json())
			.then(data => {
				setStops(data.stop_points);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}

	function handleOnClickStop(id) {
		setSelectedStop(id);
		setModal('embed2');
	}

	function handleAddEmbed() {
		const el = {
			type: selectedType,
			stop: selectedStop
		};
		handleSetValue(el);
	}

	switch (modal) {
	case 'trafic':
		return <div className='modal-back'>
			<div className='modal'>

				<div className='is-block'>
					<input
						autoFocus
						className='left is-inline-block search'
						onChange={handleOnChangeValue}
						placeholder='Ajouter ou rechercher une classification'
						type='text'
						value={value}
					/>
					<div className='space' />
					{
						value != '' &&
                            <button className='fluent_btn2 is-inline-flex left' onClick={() => handleSetValue(value)}>
                            	<img className='svg' src={add} />
                            	<span>Ajouter <b>{value}</b></span>
                            </button>
					}
				</div>
				<div className='scrollable'>
					{
						filteredData.map((logo) => <button className='fluent_btn2 is-inline-flex left' key={logo} onClick={() => handleSetValue(logo)}>
							<span>{logo}</span>
						</button>)
					}
				</div>

				<div className='footer'>
					<button onClick={handleCancel} style={{ width: 120 }}>
						<img alt='Annuler' className='svg' src={del} />
						<span>Annuler</span>
					</button>
				</div>
			</div>
		</div>;

	case 'iframe':
		return <div className='modal-back'>
			<div className='small-modal'>

				<div className='is-block'>
					<h2>Ajouter une intégration</h2>
					<div className='space' />
				</div>
				<br />
				{
					message && <span className='is-red'>{message}</span>
				}
				<LimitedInput
					maxLength={500}
					name='new_name'
					onChange={handleOnChangeIframe}
					placeholder='Intégration HTML'
					istextarea
					type='text'
					value={value}
				/>

				<div className='footer'>
					<button onClick={handleCancel} style={{ width: 120 }}>
						<img alt='Annuler' className='svg' src={del} />
						<span>Annuler</span>
					</button>

					<button className='green' disabled={message} onClick={() => handleSetValue(value)} style={{ width: 120 }}>
						<img alt='Intégrer' className='svg_white' src={check} />
						<span>Intégrer</span>
					</button>
				</div>
			</div>
		</div>;

	case 'img':
		return <div className='modal-back'>
			<div className='small-modal'>

				<div className='is-block'>
					<h2>Ajouter une image</h2>
					<div className='space' />
				</div>
				<br />
				{
					message && <span className='is-red'>{message}</span>
				}
				<EditImgUpload
					handleOnChange={(value) => handleSetValue(value)}
					type='img'
					updateData={updateData}
				/>

				<div className='footer'>
					<button onClick={handleCancel} style={{ width: 120 }}>
						<img alt='Annuler' className='svg' src={del} />
						<span>Annuler</span>
					</button>
				</div>
			</div>
		</div>;

	case 'embed':
		return <div className='modal-back'>
			<div className='modal' key='embed'>

				<div className='is-block'>
					<input
						autoFocus
						className='left is-inline-block search'
						onChange={getStops}
						placeholder='Rechercher une gare'
						type='text'
						value={value}
					/>
					<div className='space' />
				</div>
				{
					isLoading && <div className='is-block'>
						<div className='progress-bck' />
						<div className='progress indeterminate' />
					</div>
				}
				<div className='scrollable'>
					{
						stops.map((stop) => <button className='fluent_btn2 is-inline-flex left' key={stop.stop_point.tvs} onClick={() => handleOnClickStop(stop.stop_point.uic_code)}>
							<span>{stop.stop_point.name}</span>
						</button>)
					}
				</div>

				<div className='footer'>
					<button onClick={handleCancel} style={{ width: 120 }}>
						<img alt='Annuler' className='svg' src={del} />
						<span>Annuler</span>
					</button>
				</div>
			</div>
		</div>;

	case 'embed2':
		return <div className='modal-back'>
			<div className='modal' key='embed2'>

				<div className='is-block'>
					<h2>Sélectionnez votre infogare</h2>
					<div className='space' />
				</div>
				<br />

				<div className='scrollable'>
					<form className='cc-selector' style={{ maxWidth: '100%' }}>
						<input type='radio' id='SNCFd' name='opt' value='SNCF/departure' onClick={handleOnClickRadio} />
						<label className='selectcard-cc SNCFd-card' htmlFor='SNCFd' />
						<input type='radio' id='SNCFa' name='opt' value='SNCF/arrival' onClick={handleOnClickRadio} />
						<label className='selectcard-cc SNCFa-card' htmlFor='SNCFa' />
						<br /><br />
						<input type='radio' id='IENAd' name='opt' value='IENA/departure' onClick={handleOnClickRadio} />
						<label className='selectcard-cc IENAd-card' htmlFor='IENAd' />
						<input type='radio' id='IENAa' name='opt' value='IENA/arrival' onClick={handleOnClickRadio} />
						<label className='selectcard-cc IENAa-card' htmlFor='IENAa' />
						<br /><br />
						<input type='radio' id='TALOSd' name='opt' value='TALOS/departure' onClick={handleOnClickRadio} />
						<label className='selectcard-cc TALOSd-card' htmlFor='TALOSd' />
						<input type='radio' id='TALOSa' name='opt' value='TALOS/arrival' onClick={handleOnClickRadio} />
						<label className='selectcard-cc TALOSa-card' htmlFor='TALOSa' />
					</form>
				</div>

				<div className='footer'>
					<button onClick={handleCancel} style={{ width: 120 }}>
						<img alt='Annuler' className='svg' src={del} />
						<span>Annuler</span>
					</button>

					<button className='green' disabled={!selectedStop || !selectedType} onClick={handleAddEmbed} style={{ width: 120 }}>
						<img alt='Intégrer' className='svg_white' src={add} />
						<span>Ajouter</span>
					</button>
				</div>
			</div>
		</div>;

	case 'savepage':
		return <div className='modal-back'>
			<div className='mini-modal' key='embed2'>

				<div className='is-block'>
					<h2>Sauvegarde en cours</h2>
					<div className='space' />
				</div>
				<br />
				<div className='center'>
					<img src={save} alt='illustration' style={{ maxHeight: 60 }} />
				</div>
				<p>
                        Nous vérifions et sauvegardons cette page.
				</p>
				<div className='footer'>
					<div className='is-block' style={{ width: '100%' }}>
						<div className='progress-bck' />
						<div className='progress indeterminate' />
					</div>
				</div>
			</div>
		</div>;

	default:
		return null;

	}
}

export default EditModal;
