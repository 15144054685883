import React from 'react';
import LogoInput from '../helpers/LogoInput';
import { Link } from 'react-router-dom';

import arrow_left from '../assets/img/arrow-left.svg';
import LogoUpload from '../helpers/LogoUpload';


function Logos({ data, handleOnChange, updateData }) {
	return (
		<div>
			<Link to='/settings/images' className='btn fluent_btn is-inline-flex' style={{ width: '105px' }} >
				<img className='svg' src={arrow_left} /> <span>Retour</span>
			</Link>
			<br />
			<h3>Ajouter des logos de classification pour vos trains</h3>
            Privilégiez les logos au format carré et avec un fond transparent (mais rien ne vous y oblige 😉).
			<div className='blue'>
                Par défaut, le logo de classification est le <i>petit logo</i> de votre compagnie.
			</div>
			<LogoUpload
				handleOnChange={handleOnChange}
				type='class'
				updateData={updateData}
			/>
			<div className='block'>
				<b className='block'>Mes logos : </b>
				{
					data.logos.class.map((item, index) =>
						<LogoInput
							company={data['account']['name']}
							defined
							handleOnChange={handleOnChange}
							id={item}
							index={index}
							key={item}
							label={item}
							noInput
							type='class'
							updateData={updateData}
						/>
					)
				}
			</div>
			<br />
		</div>

	);
}

export default Logos;