import shortid from 'shortid';

export const SIDEBAR_ITEM = 'sidebarItem';
export const ROW = 'row';
export const COLUMN = 'column';
export const COMPONENT = 'component';

export const ROW_DEFAULT = {
	type: ROW,
	style: 'row1',
	shadow: true,
	color: 'body'
};

export const COLUMN_DEFAULT = {
	type: COLUMN,
};

import text from '../../assets/img/text.png';
import img from '../../assets/img/stack-of-photos.png';
import white_space from '../../assets/img/white-space.svg';
import horizontal_line from '../../assets/img/horizontal-line.svg';
import source_code from '../../assets/img/source-code.png';
import search from '../../assets/img/search.svg';
import about from '../../assets/img/about.svg';
import property from '../../assets/img/property.svg';
import head from '../../assets/img/head.svg';

export const SIDEBAR_ITEMS = [
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: text,
		component: {
			name: 'Texte',
			type: 'input',
			content: [
				{
					type: 'paragaph',
					children: [{ text: '' }],
				},
			],
		}
	},
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: source_code,
		component: {
			name: 'Intégration',
			type: 'iframe',
			needInit: true
		}
	},
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: img,
		component: {
			name: 'Images',
			type: 'img',
			needInit: true
		}
	}
];

export const SIDEBAR_SMALL_ITEMS = [
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: horizontal_line,
		component: {
			name: 'Séparateur',
			type: 'hr'
		}
	},
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: white_space,
		component: {
			name: 'Espace',
			type: 'space'
		}
	}
];

export const SIDEBAR_LEGACY_ITEMS = [
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: head,
		component: {
			name: 'En-tête',
			type: 'head'
		}
	}
];

export const SIDEBAR_SPEC_ITEMS = [
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: about,
		component: {
			name: 'Info trafic',
			type: 'trafic',
			needInit: true
		}
	},
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: property,
		component: {
			name: 'Infogare',
			type: 'embed',
			needInit: true
		}
	},
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: search,
		component: {
			name: 'Zone de recherche',
			type: 'search'
		}
	},
	
];

/*
	SIDEBAR_SPEC_ITEMS
	{
		id: shortid.generate(),
		type: SIDEBAR_ITEM,
		img: white_space,
		component: {
			name: 'Itinéraires',
			type: 'space',
			needInit: true
		}
	}
*/