/* eslint-disable no-unused-vars */
import shortid from 'shortid';
import { COLUMN, COMPONENT, ROW_DEFAULT, COLUMN_DEFAULT } from './constants';
import { colors } from '../../defaultColors.js';

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed); // inserting task in new index

	return result;
};

export const remove = (arr, index) => [
	// part of the array before the specified index
	...arr.slice(0, index),
	// part of the array after the specified index
	...arr.slice(index + 1)
];

export const insert = (arr, index, newItem) => [
	// part of the array before the specified index
	...arr.slice(0, index),
	// inserted item
	newItem,
	// part of the array after the specified index
	...arr.slice(index)
];

export const getColorOfElement = (color) => {
	switch (true) {
	case color === 'body':
		return 'var(--body)';
	case color === 'primary':
		return 'var(--el-back-primary)';
	case color === 'secondary':
		return 'var(--el-back-secondary)';

	case color === 'blue':
		return 'var (--blue-primary)';
	case color === 'green':
		return 'var(--green-primary)';
	case color === 'red':
		return 'var(--red-primary)';
	case color === 'orange':
		return 'var(--orange-primary)';
	case color === 'transparent':
		return 'transparent';

	case colors.includes(color.toUpperCase()):
		return color;

	case colors.includes(`#${color.toUpperCase()}`):
		return `#${color}`;

	default:
		return 'var(--body)';
	}
};

export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {
	if (splitDropZonePath.length === 1) {
		const dropZoneIndex = Number(splitDropZonePath[0]);
		const itemIndex = Number(splitItemPath[0]);
		return reorder(children, itemIndex, dropZoneIndex);
	}

	const updatedChildren = [...children];

	const curIndex = Number(splitDropZonePath.slice(0, 1));

	// Update the specific node's children
	const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
	const splitItemChildrenPath = splitItemPath.slice(1);
	const nodeChildren = updatedChildren[curIndex];
	updatedChildren[curIndex] = {
		...nodeChildren,
		children: reorderChildren(
			nodeChildren.children,
			splitDropZoneChildrenPath,
			splitItemChildrenPath
		)
	};

	return updatedChildren;
};

export const removeChildFromChildren = (children, splitItemPath) => {
	if (splitItemPath.length === 1) {
		const itemIndex = Number(splitItemPath[0]);
		return remove(children, itemIndex);
	}

	const updatedChildren = [...children];

	const curIndex = Number(splitItemPath.slice(0, 1));

	// Update the specific node's children
	const splitItemChildrenPath = splitItemPath.slice(1);
	const nodeChildren = updatedChildren[curIndex];
	updatedChildren[curIndex] = {
		...nodeChildren,
		children: removeChildFromChildren(
			nodeChildren.children,
			splitItemChildrenPath
		)
	};

	return updatedChildren;
};

export const addChildToChildren = (children, splitDropZonePath, item) => {
	if (splitDropZonePath.length === 1) {
		const dropZoneIndex = Number(splitDropZonePath[0]);
		return insert(children, dropZoneIndex, item);
	}

	const updatedChildren = [...children];

	const curIndex = Number(splitDropZonePath.slice(0, 1));

	// Update the specific node's children
	const splitItemChildrenPath = splitDropZonePath.slice(1);
	const nodeChildren = updatedChildren[curIndex];
	updatedChildren[curIndex] = {
		...nodeChildren,
		children: addChildToChildren(
			nodeChildren.children,
			splitItemChildrenPath,
			item
		)
	};

	return updatedChildren;
};

export const handleMoveWithinParent = (
	layout,
	splitDropZonePath,
	splitItemPath
) => {
	return reorderChildren(layout, splitDropZonePath, splitItemPath);
};

export const handleAddColumDataToRow = layout => {
	const layoutCopy = [...layout];
	const COLUMN_STRUCTURE = {
		type: COLUMN,
		id: shortid.generate(),
		children: []
	};

	return layoutCopy.map(row => {
		if (!row.children.length) {
			row.children = [COLUMN_STRUCTURE];
		}
		return row;
	});
};

export const handleMoveToDifferentParent = (layout, splitDropZonePath, splitItemPath, item) => {
	let newLayoutStructure;
	let newComponents = {};

	const ROW_STRUCTURE = {
		...ROW_DEFAULT,
		id: shortid.generate()
	};

	const COLUMN_STRUCTURE = {
		...COLUMN_DEFAULT,
		id: shortid.generate(),
	};

	switch (splitDropZonePath.length) {
	case 1: {
		// moving column outside into new row made on the fly
		if (item.type === COLUMN) {
			newComponents = {
				[ROW_STRUCTURE.id]: structuredClone(ROW_STRUCTURE),
			};
			newLayoutStructure = {
				...ROW_STRUCTURE,
				children: [item]
			};
		} else {
			// moving component outside into new row made on the fly
			newComponents = {
				[COLUMN_STRUCTURE.id]: structuredClone(COLUMN_STRUCTURE),
				[ROW_STRUCTURE.id]: structuredClone(ROW_STRUCTURE),
			};
			COLUMN_STRUCTURE.children = [item];
			newLayoutStructure = {
				...ROW_STRUCTURE,
				children: [COLUMN_STRUCTURE]
			};
		}
		break;
	}
	case 2: {
		// moving component outside into a row which creates column
		if (item.type === COMPONENT) {
			newComponents = {
				[COLUMN_STRUCTURE.id]: structuredClone(COLUMN_STRUCTURE),
			};
			COLUMN_STRUCTURE.children = [item];
			newLayoutStructure = COLUMN_STRUCTURE;
		} else {
			// moving column into existing row
			newLayoutStructure = item;
		}
		break;
	}
	default: {
		newLayoutStructure = item;
	}
	}

	let updatedLayout = layout;
	updatedLayout = removeChildFromChildren(updatedLayout, splitItemPath);
	updatedLayout = handleAddColumDataToRow(updatedLayout);
	updatedLayout = addChildToChildren(updatedLayout, splitDropZonePath, newLayoutStructure);

	return {
		newLayout: updatedLayout,
		newComponents
	};
};

export const handleMoveSidebarComponentIntoParent = (layout, splitDropZonePath, item) => {
	let newLayoutStructure;
	let newComponents = {};

	const ROW_STRUCTURE = {
		...ROW_DEFAULT,
		id: shortid.generate()
	};
	const COLUMN_STRUCTURE = {
		...COLUMN_DEFAULT,
		id: shortid.generate(),
	};

	switch (splitDropZonePath.length) {
	case 1: {
		newComponents = {
			[COLUMN_STRUCTURE.id]: structuredClone(COLUMN_STRUCTURE),
			[ROW_STRUCTURE.id]: structuredClone(ROW_STRUCTURE),
		};

		COLUMN_STRUCTURE.children = [item];
		ROW_STRUCTURE.children = [COLUMN_STRUCTURE];
		newLayoutStructure = ROW_STRUCTURE;

		break;
	}
	case 2: {
		newComponents = {
			[COLUMN_STRUCTURE.id]: structuredClone(COLUMN_STRUCTURE),
		};

		COLUMN_STRUCTURE.children = [item];

		newLayoutStructure = COLUMN_STRUCTURE;
		break;
	}
	default: {
		newLayoutStructure = item;
	}
	}

	return {
		newLayout: addChildToChildren(layout, splitDropZonePath, newLayoutStructure),
		newComponents
	};
};

export const handleRemoveItemFromLayout = (layout, splitItemPath) => {
	return removeChildFromChildren(layout, splitItemPath);
};
