import React, { useRef, useState } from 'react';
import update from 'react-addons-update';
import { useDrag } from 'react-dnd';
import { ROW } from './constants';
import DropZone from './DropZone';
import Column from './Column';
import OutsideClickHandler from '../../helpers/OutsideClick';
import { getColorOfElement } from './helpers';

import paint from '../../assets/img/paint.svg';
import brush from '../../assets/img/brush.svg';
import RowColorPicker from './RowColorPicker';
import RowStylePicker from './RowStylePicker';

const Row = ({ data, row, components, handleDrop, path, isMoving, setIsMoving, setComponents, disabled }) => {

	const [isFocused, setIsFocused] = useState(false);
	const [menu, setMenu] = useState(false);

	const ref = useRef(null);

	const [{ isDragging }, drag] = useDrag({
		type: ROW,
		item: () => {
			setIsMoving('true');
			return {
				type: ROW,
				id: row.id,
				children: row.children,
				path
			};
		},
		collect: monitor => ({
			isDragging: monitor.isDragging()
		}),
		end: () => {
			setIsMoving(false);
		},
		canDrag: () => !disabled
	});

	const opacity = isDragging ? 0 : 1;
	drag(ref);

	const renderColumn = (column, currentPath) => <Column
		key={column.id}
		disabled={disabled}
		data={data}
		column={column}
		components={components}
		setComponents={setComponents}
		handleDrop={handleDrop}
		path={currentPath}
		setIsMoving={setIsMoving}
		isMoving={isMoving}
	/>;


	const component = components[row.id];

	const style = {};
	const border = isFocused ? 'solid 3px var(--blue-primary)' : 'solid 3px var(--transparent)';

	function handleOnClick() {
		setIsFocused(true);
	}

	function handleOnBlur() {
		setIsFocused(false);
	}

	function handleChangeColor(color) {
		if (color == 'body' || color == 'transparent') {
			setComponents(components => update(components, { [row.id]: { 'color': { $set: color } } }));
		} else {
			setComponents(components => update(components, { [row.id]: { 'color': { $set: color['hex'] } } }));
		}
	}

	function handleChangeStyle(style) {
		setComponents(components => update(components, { [row.id]: { 'style': { $set: style } } }));
	}

	function handleChangeShadow(shadow) {
		setComponents(components => update(components, { [row.id]: { 'shadow': { $set: shadow } } }));
	}

	var shadow_var = '';
	if (component.shadow) {
		shadow_var = '0 0 20px 0 rgba(0,0,0,.5),0 5px 5px 0 rgba(0,0,0,.24)';
	}

	return <OutsideClickHandler disabled={disabled} onClick={handleOnClick} onOutsideClick={handleOnBlur}>
		{
			isFocused &&
			<div className='container control'>
				<button className='mini_btn' onClick={() => setMenu('color')}>
					<img className='svg' src={paint} />
				</button>
				<button className='mini_btn' onClick={() => setMenu('style')}>
					<img className='svg' src={brush} />
				</button>
			</div>
		}
		{
			isFocused &&
			<OutsideClickHandler disabled={disabled} onOutsideClick={() => setMenu(false)}>
				{
					menu == 'color' && <RowColorPicker
						color={getColorOfElement(component.color)}
						handleChangeColor={handleChangeColor}
					/>
				}
				{
					menu == 'style' && <RowStylePicker
						style={component.style}
						shadow={component.shadow}
						color={getColorOfElement(component.color)}
						handleChangeStyle={handleChangeStyle}
						handleChangeShadow={handleChangeShadow}
					/>
				}
			</OutsideClickHandler>
		}

		<div className={disabled ? 'container' : 'base draggable container'} ref={ref} style={{ ...style, border, opacity }}>
			<div style={{ backgroundColor: getColorOfElement(component.color), boxShadow: shadow_var }} className={`row ${component.style}`}>
				<div className='columns'>
					{row.children.map((column, index) => {
						const currentPath = `${path}-${index}`;

						return <>
							<DropZone
								disabled={disabled}
								data={data}
								dropData={{
									path: currentPath,
									childrenCount: row.children.length,
								}}
								onDrop={handleDrop}
								isMoving={isMoving}
								className='horizontalDrag'
							/>
							{renderColumn(column, currentPath)}
						</>;
					})}
					<DropZone
						disabled={disabled}
						data={data}
						dropData={{
							path: `${path}-${row.children.length}`,
							childrenCount: row.children.length
						}}
						onDrop={handleDrop}
						isMoving={isMoving}
						className='horizontalDrag'
						isLast
					/>
				</div>
			</div>
		</div>
	</OutsideClickHandler>;
};
export default Row;
