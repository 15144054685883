import React, { useState } from 'react';
import Toggle from 'react-toggle';
import { SpinnerCircularFixed } from 'spinners-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toast_error } from '../styles';

import Info from '../helpers/Info';

import reset from '../assets/img/reset.svg';
import save from '../assets/img/save.svg';
import add from '../assets/img/add.svg';
import edit from '../assets/img/edit.svg';

function Disruptions({ data, handleOnChange, updateData }) {
	async function handleChangeDisruptionsIsPersonalized(event) {
		setIsLoadingDisruptionsIsPersonalized(true);
		const value = event.target.checked;
		const { code, response } = await updateData('disruptions_is_personalized', value);

		if (code === 200) {
			handleOnChange({ 'disruptions': { 'personalized': { $set: (response['response']['value'] === true) } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingDisruptionsIsPersonalized(false);
	}

	async function handleSaveRules(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingSaveRules(true);
		const { code, response } = await updateData('set_disruptions_rules', data.disruptions.rules);

		if (code === 200) {
			handleOnChange({ 'disruptions': { 'rules': { $set: response } } });
			toast.success('Enregistré avec succès', toast_error);
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingSaveRules(false);
	}
	async function handleGetRules(event) {
		event.preventDefault();
		event.currentTarget.blur();

		setIsLoadingGetRules(true);
		const { code, response } = await updateData('get_disruptions_rules', '');

		if (code === 200) {
			handleOnChange({ 'disruptions': { 'rules': { $set: response } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingGetRules(false);
	}

	const default_disruptions = {
		'name': '',
		'title': '',
		'details': '',
		'status': 'info',
		'has_expiration': false,
		'expiration': '',
		'is_delayed': false,
		'delay': '',
		'is_deleted': false,
	};

	function handleAddInfo(event, type) {
		event.preventDefault();
		event.currentTarget.blur();

		handleOnChange({ 'disruptions': { 'rules': { [type]: { $push: [default_disruptions] } } } });
	}

	function handleRemoveInfo(event, index, type) {
		event.preventDefault();
		event.currentTarget.blur();

		handleOnChange({ 'disruptions': { 'rules': { [type]: { $splice: [[index, 1]] } } } });
	}

	function handleChangeRule(value, index, info, type) {
		handleOnChange({ 'disruptions': { 'rules': { [type]: { [index]: { [info]: { $set: value } } } } } });
	}


	const [isLoadingDisruptionsIsPersonalized, setIsLoadingDisruptionsIsPersonalized] = useState(false);

	const [isLoadingSaveRules, setIsLoadingSaveRules] = useState(false);
	const [isLoadingGetRules, setIsLoadingGetRules] = useState(false);

	return (
		<div>
			<div className='block'>
				<h3>Personnalisation de la page d’info trafic</h3> <br />
				<form className='fluent_form left'>
					<Toggle
						checked={data['disruptions']['personalized']}
						icons={false}
						id='show_price'
						onChange={handleChangeDisruptionsIsPersonalized}
					/>
					{isLoadingDisruptionsIsPersonalized
						? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
						: <label htmlFor='visible'>{data['disruptions']['personalized'] ? 'Personnalisé' : 'Par défaut'}</label>
					}
					<br /><br />
					{
						data['disruptions']['personalized'] === true
                        && <Link to='/settings/disruptions/edit' className='btn is-inline-flex blue' style={{ width: '300px' }}>
                        	<img className='svg_white' src={edit} />
                        	<span>Editer</span>
                        </Link>
					}
				</form>
			</div>
			<div className='block'>
				<h3>Ajouter des information trafic </h3>

				<div className='ribbon'>
					<button className='is-inline-flex' onClick={handleGetRules} style={{ width: '130px' }}>
						{
							isLoadingGetRules
								? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
								: <img className='svg' src={reset} />
						}
						<span>Actualiser</span>
					</button>
					<button className='is-inline-flex' onClick={handleSaveRules} style={{ width: '155px' }}>
						{
							isLoadingSaveRules
								? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
								: <img className='svg' src={save} />
						}
						<span>Sauvegarder</span>
					</button>
					<span className='info' />
				</div>

				<div className='blue'>
                    Ajouter un astérisque * au début pour inclure toutes les noms ou classification. <br /> *TER inclus tout les trains comprenant TER (inTERcités aussi).<br />
				</div>


				<div className='block' >
					<b className='is-block'> Information(s) masqué la classification du train : </b>

					{
						data.disruptions.rules.class.map((item, i) =>
							<Info i={i}
								item={item}
								key={i}
								label='Classification'
								onChange={(event, index, info, type) => handleChangeRule(event, index, info, type)}
								onRemove={handleRemoveInfo}
								type='class'
							/>
						)
					}

					<button className='fluent_btn is-inline-flex ml-5 mt-2 mb-4 left' onClick={(e) => handleAddInfo(e, 'class')} style={{ width: 'calc(100% - 36px)' }}>
						<img className='svg' src={add} />
						<span>Ajouter une information</span>
					</button>

					<br />
					<b className='is-block'> Information(s) masqué selon leur nom : </b>

					{
						data.disruptions.rules.name.map((item, i) =>
							<Info i={i}
								item={item}
								key={i}
								label='Nom'
								onChange={(event, index, info, type) => handleChangeRule(event, index, info, type)}
								onRemove={handleRemoveInfo}
								type='name'
							/>
						)
					}

					<button className='fluent_btn is-inline-flex ml-5 mt-2 mb-4 left' onClick={(e) => handleAddInfo(e, 'name')} style={{ width: 'calc(100% - 36px)' }}>
						<img className='svg' src={add} />
						<span>Ajouter une information</span>
					</button>

				</div>
			</div>
		</div>
	);
}

export default Disruptions;