import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { settings_route, help_route, admin_route } from './routes';


function ScrollBlock({ data }) {
	const location = useLocation();
	const path = location.pathname;

	const renderbutton = (routes) => {
		const filtered = routes.filter((route) => route.visible == true);

		return filtered.map((route) => {
			if (route.id == 'apps' && data['apps'].length == 0) {
				return null;
			}
			const selected = isSelected(path, route);
			return <Link to={`/settings/${route.id}`} key={route.id} >
				<div className={`path-link ${selected && 'selected'}`}>
					<img alt={route.label} className='svg' src={route.img} />
					{
						route.is_beta && <b className='is-red'>Beta</b>
					}
					<span>{route.label}</span>
				</div>
			</Link>;
		});
	};

	return (
		<div className='settings-scroll'>
			<h2>Paramètres</h2>

			<div className='scroll'>
				{renderbutton(settings_route)}
				<span className='space' />
				{renderbutton(help_route)}
				{
					data['isAdmin'] &&
                    <>
                    	<span className='space' />
                    	{renderbutton(admin_route)}
                    </>
				}

			</div>
		</div>
	);
}

function isSelected(path, route) {
	return (path === '/settings' && route.id == 'home')
        || path === `/settings/${route.id}`
        || (route.for && route.for.includes(path.replace('/settings/', '')));
}

export default ScrollBlock;