import React from 'react';

import help from './assets/img/help.svg';
import del from './assets/img/delete.svg';
import logout from './assets/img/logout.svg';
import slide from './assets/img/help/slide.gif';

import InfoO from './assets/img/help/InfoO.png';
import InfoN from './assets/img/help/InfoN.png';
import Nclassique from './assets/img/help/Nclassique.png';
import Nmoderne from './assets/img/help/Nmoderne.png';

function Modal({ modal, setModal }) {
	switch (modal) {
		case 'logout':
			return <div className='modal-back'>
				<div className='mini-modal'>
					<h2>Se déconnecter ?</h2>
					<div className='space' /><br />

					<div className='footer'>
						<button onClick={() => setModal(null)} style={{ width: 120 }}>
							<img alt='Fermer' className='svg' src={del} />
							<span>Fermer</span>
						</button>

						<a className='btn is-inline-flex red' href='/logout' style={{ width: 150 }}>
							<img alt='Supprimer' className='svg_white' src={logout} />
							<span>Déconnexion</span>
						</a>
					</div>
				</div>
			</div>;

		case 'help_pages':
			return <div className='modal-back'>
				<div className='modal'>
					<img alt='Aide' className='himg svg' src={help} />
					<h2 className='ml-2 is-inline-block'>Aide • Pages</h2>
					<div className='space' /><br />
					<div className='is-block'>
						<img alt='aide' src={slide} style={{ maxWidth: '100%' }} />
					</div>

					<div className='footer'>
						<button onClick={() => setModal(null)} style={{ width: 120 }}>
							<img alt='Fermer' className='svg' src={del} />
							<span>Fermer</span>
						</button>
					</div>
				</div>
			</div>;

		case 'help_show_trafic':
			return <div className='modal-back'>
				<div className='modal'>
					<img alt='Aide' className='himg svg' src={help} />
					<h2 className='ml-2 is-inline-block'>Aide • Info trafic</h2>
					<div className='space' /><br />
					<div className='is-block'>
						<table>
							<tbody>
								<tr>
									<td><h2> Masqué </h2></td>
									<td><h2> Affiché </h2></td>
								</tr>
								<tr>
									<td> <img src={InfoN} style={{ width: '95%' }} /> </td>
									<td> <img src={InfoO} style={{ width: '95%' }} /> </td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className='footer'>
						<button onClick={() => setModal(null)} style={{ width: 120 }}>
							<img alt='Fermer' className='svg' src={del} />
							<span>Fermer</span>
						</button>
					</div>
				</div>
			</div>;

		case 'help_style_nav_bar':
			return <div className='modal-back'>
				<div className='modal'>
					<img alt='Aide' className='himg svg' src={help} />
					<h2 className='ml-2 is-inline-block'>Aide • Barre de navigation</h2>
					<div className='space' /><br />
					<div className='is-block'>
						<table>
							<tbody>
								<tr>
									<td><h2> Masqué </h2></td>
									<td><h2> Affiché </h2></td>
								</tr>
								<tr>
									<td> <img src={Nclassique} style={{ width: '95%' }} /> </td>
									<td> <img src={Nmoderne} style={{ width: '95%' }} /> </td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className='footer'>
						<button onClick={() => setModal(null)} style={{ width: 120 }}>
							<img alt='Fermer' className='svg' src={del} />
							<span>Fermer</span>
						</button>
					</div>
				</div>
			</div>;

		default:
			return null;

	}
}

export default Modal;
