import React from 'react';
import Footer from '../../Footer';

import rules from '../../assets/img/rules.svg';

function CGU() {
	return <div className='doc'>
		<img alt='Conditions générales' className='himg svg' src={rules} />
		<h2 className='ml-2 is-inline-block'>Conditions générales</h2>

		<div className='blue is-block'>
            Mis à jour le <b>15 Mars 2023</b>
		</div>
		<div className='blue'>
			<a className='link_blue' href='docs/cgu.pdf' target='_blank'>Lire la version imprimable</a>
		</div>
		<br />
		<h2>Mentions légales et conditions générales d’utilisation</h2>
		<h3>Introduction</h3>
		<p>
            Ces présentes conditions sont portées à la connaissance des utilisateurs conformément à l’article 6-III et 19 de
            la LCEN.
		</p>
		<div className='blue'>
            ➜ <a className='link_blue' href='https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000801164/'>https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000801164/</a>
		</div>
		<p />
		<p>
            Ces conditions introduisent les règles d’utilisation entre MyLines (accessible à l’url https://beta.hackernwar.com) et
            les utilisateurs. Les termes « site », « la plateforme », réfèrent à MyLines, son administration ou son
            créateur.
		</p>
		<p>
            L’accès et l’utilisation de MyLines est soumis au cadre législatif français et aux présentes conditions. Ainsi,
            l’utilisation de MyLines implique l’acceptation de l’ensemble des conditions des « Mentions légales et
            conditions générale d’utilisation »
		</p>
		<p>
            Notez que ce règlement peut être amené à évoluer. Dans ce cas une notification apparaitra dans la rubrique « Mon
            Compte ».
		</p>
		<br />
		<h3>1. Loi applicable et juridiction</h3>
		<p>
            MyLines est un site français, de ce fait les lois françaises régissent ces présentes mentions ainsi que
            l’utilisation de MyLines par les utilisateurs. <br /><br />
            En cas de conflit, à défaut d’accord à l’amiable, le litige sera porté devant les tribunaux français.
		</p>
		<br />
		<h3>2. Confidentialité et données personnelles</h3>
		<h4>2.1 Données collectées et traitement</h4>
		<p>
            Dans le cadre de l’application du RGPD, le règlement général sur la protection des données, règlement nᵒ
            2016/679. Le site ne recueille que les données personnelles nécessaire à son fonctionnement et sa sécurité. Les
            données collectées sont :
		</p>
		<ul>
			<li>L’adresse IP <i>Pour des raisons de sécurité</i> </li>
			<li>Adresse Mail <i>Permettant un contact avec les membres</i> </li>
			<li>Date de la dernière connexion </li>
		</ul>
		<p>
            Ces données sont conservées sur une durée maximale de 4 mois à partir de la dernière date de connexion à
            MyLines. De ce fait, au-delà de 4 mois sans connexion à MyLines, l’entièreté de ces données seront supprimées.
		</p>
		<p>
            L’utilisateur comprend également que l’entièreté des données que l’utilisateur a saisies ainsi que les données
            collectées par le site soient enregistrées dans la base de données de MyLines.
		</p>
		<br />
		<h4>2.2 Confidentialité et sécurité</h4>
		<p>
            Les données de connexions (identifiant, mot de passe, …) à MyLines sont confidentielles et ne doivent par
            conséquent pas communiqué à un tiers. L’administration ne peut être tenu responsable en cas de modification,
            suppression ou vol de données personnelles si ces informations de connexions sont transmises à un tiers.
		</p>
		<br />
		<h4>2.3 Hébergement des données</h4>
		<p>
            Les données de MyLines sont hébergées en France chez OVH, dont le siège social est situé à Roubaix, 2 rue
            Kellermann – 59100 Roubaix
		</p>
		<div className='blue'>
            ➜ <a className='link_blue' href='https://www.ovh.com'>https://www.ovh.com</a>
		</div>
		<br />
		<h4>2.4 Droit de l’utilisateur</h4>
		<p>
            L’utilisateur peut demander à tout moment la suppression, consultation ou rectification de ses données.
            L’administration devant répondre dans un délai raisonnable. Les demandes abusives peuvent quant à elle être
            rejeté par l’administration.
		</p>
		<p>
            Si besoin, vous pouvez contactez l’autorité compétente en matière de données personnelles, la CNIL en France
		</p>
		<div className='blue'>
            ➜ <a className='link_blue' href='https://www.cnil.fr'>https://www.cnil.fr</a>
		</div>
		<br />
		<h4>2.5 Obligations de la plateforme</h4>
		<p>
            Les données personnelles collectées ne sont et seront aucunement partagées à des tiers sans le consentement de
            l’utilisateurs.
		</p>
		<p>
            La communication entre la plateforme et les utilisateurs est sécurisée par le chiffrement HTTPS.
		</p>
		<p>
            La plateforme s’engage à protéger du mieux qu’elle peut les données, leurs intégrités et la confidentialité de
            celles-ci.
		</p>
		<p>
            Néanmoins, notez que malgré les efforts visant à protéger la plateforme, la sécurité de celle-ci ne peut être
            entièrement garantie et l’administration de MyLines s’engage à informer les utilisateurs dans le cas ou leurs
            données personnelles auraient été compromises.
		</p>
		<br />
		<h4>2.6 Cookies</h4>
		<p>
            Les cookies sont des informations déposées par le site et sont stockées sur le navigateur internet de
            l’utilisateur. MyLines peut, pour un fonctionnement optimal utiliser des cookies. Tel que des cookies de
            session.
		</p>
		<p>
            Notez que vous pouvez vous opposer à l’enregistrement des cookies en paramétrant votre navigateur. Dans ce cas
            référez-vous a la documentation de votre navigateur.
		</p>
		<p>
            Google peut avec la fonctionnalité facultative « Se connecter avec Google » déposer des cookies.
		</p>
		<p>
            Si besoin les conditions d’utilisation de Google sont disponible à l’adresse suivante :
		</p>
		<div className='blue'>
            ➜ <a className='link_blue' href='https://policies.google.com/terms'>https://policies.google.com/terms</a>
		</div>
		<br />
		<h3>3. Utilisation courante</h3>
		<p>
            Lors de l’utilisation de MyLines, l’utilisateur s’engage à signaler auprès de l’administration, tous bugs ou
            failles quelconques rencontrées. L’utilisation d’un de ces bugs ou failles est strictement interdite et peut
            donner suites à des poursuites judiciaires et/ou signalement aux autorités compétentes.
		</p>
		<p>
            L’utilisateur comprend que les données saisies ne doivent en aucun cas porter atteinte à autrui et il doit
            s’assurer de respecter le droit d’image, le droit au respect de la vie privée des personnes pouvant être visé et
            de ne pas diffamer ou dénigrer ceux-ci.
		</p>
		<p>
            L’utilisateur s’engage à ne pas contrevenir à la législation (propos incitant à la haine, apologie du
            terrorisme), ni publier des propos, photos, vidéos ou liens à caractère pornographique ou violent.
            L’administration se réserve le droit à la suppression de ces données ainsi qu’à des sanctions ou poursuites
            judiciaires.
		</p>
		<p>
            L’administration ne peut être tenu responsable de la non-licéité du contenu publié par les utilisateurs.
		</p>
		<br />
		<h3>4. Droits et propriété intellectuelle</h3>
		<h4>4.1 Droit de la plateforme</h4>
		<p>
            Le site MyLines, son code source, ces logos et éléments graphiques de la plateforme sont la propriété exclusive
            de Clément Fresse.
		</p>
		<p>
            Toute copie/usurpation totale ou même partielle de MyLines ou bien l’utilisation de la plateforme à des fins
            commerciales sans autorisation expresse du créateur peut donner suites à des poursuites judicaire.
		</p>
		<p>
            Le contenu ajouté par les utilisateurs n’est pas concerné par cette catégorie.
		</p>
		<br />
		<h4>4.2 Droit d’auteur</h4>
		<p>
            L’utilisateur qui ajoute du contenu sur MyLines conserve la pleine possession du droit moral dont il bénéficie.
            L’utilisateur peut à tout moment demander à la plateforme la suppression ou le retrait d’une de ces œuvres.
		</p>
		<p>
            L’utilisateur comprend qu’il ne peut demander quelconque rémunération a la plateforme pour l’utilisation de son
            œuvre.
		</p>
		<p>
            L’ajout de contenu soumis à des droits de propriété intellectuelle est strictement interdit si l’utilisateur ne
            possède d’autorisation de la part du créateur ou ayant droit du contenu.
		</p>
		<br />
		<h4>4.3 Procédure de retrait</h4>
		<p>
            Si vous constatez l’utilisation illégale d’une de vos œuvres, constituant une infraction aux droits d’auteurs.
            Une notification peut être adresse à l’administration pour demander le retrait du contenu en question.
		</p>
		<br />
		<h3>5. Disposition pour personnes mineures</h3>
		<p>
            Toutes personnes n’ayant pas atteint la majorité légale applicable dans son Etat de résidence ou le cas échéant,
            la majorité numérique de son Etat de résidence (16 ans en France) doivent obtenir l’accord d’un représentant
            légal pour la consultation ou utilisation de MyLines.
		</p>
		<br />
		<h3>6. Accessibilité et durée de vie</h3>
		<p>
            L’accès à MyLines n’est pas garanti et peut être perturbé en raison de panne temporaire ou de maintenance. Si
            MyLines viendrait à fermer définitivement, les utilisateurs seront prévenus par mail sur leur adresse mail de
            contact et d’autres canaux tel que le serveur discord.
		</p>
		<p>
            Un compte est disponible et accessible pendant une durée de 4 mois après la date de la dernière connexion.
		</p>
		<p>
            A partir de 4 mois sans connexion et authentification à la plateforme, l’accès au compte est désactivé et
            l’entièreté des données liée au compte sont supprimées au maximum trois jours après cette date.
		</p>
		<p>
            Un mail d’avertissement est envoyé à l’utilisateur 1 mois en amont de la désactivation de son compte.
		</p>
		<br />
		<h3>7. Se connecter avec Google</h3>
		<p>
            MyLines propose la fonctionnalité facultative « Se connecter avec Google ».
		</p>
		<p>
            Cette fonctionnalité est permise par l’entreprise tierce « Google Ireland Limited ».
		</p>
		<p>
            Si vous l’utilisez-vous comprenez que MyLines peut accéder à votre adresse mail, nom, prénom, photo de profil,
            et vos préférence linguistique.
		</p>
		<p>
            Seul votre adresse mail ainsi que l’identifiant de votre compte Google sont stocker par MyLines à des fins
            d’identification de l’utilisateur et de contact avec ce dernier.
		</p>
		<p>
            En utilisant cette fonctionnalité, Google est susceptible de déposer des cookies sur votre navigateur.
		</p>
		<p>
            L’utilisation de cette fonctionnalité implique l’acceptation des conditions d’utilisation de Google qui sont
            disponible à l’adresse suivantes :
		</p>
		<div className='blue'>
            ➜ <a className='link_blue' href='https://policies.google.com/terms'>https://policies.google.com/terms</a>
		</div>
		<br />
		<h3>8. Sanctions </h3>
		<p>
            En cas de manquement aux règles d’utilisation, des sanctions telles qu’indiqué ci-dessous peuvent être appliqué
            sans préavis de la part de l’administration.
		</p>
		<ul>
			<li>Un avertissement.</li>
			<li>La désactivation temporaire ou la suppression définitive du compte de l’utilisateur.</li>
			<li>Le bannissement de la plateforme.</li>
			<li>Des poursuites judiciaires en cas d’infraction pénale. La publication de contenu pornographique ou violent
                en faisant parti.</li>
		</ul>
		<br />
		<h3>9. Contact</h3>
		<p>
            Le créateur de MyLines est Clément Fresse.
		</p>
		<p>
            Si besoin, l’administration de MyLines peut être contacté à l’adresse mail :
		</p>
		<div className='blue'>
            ➜ <a className='link_blue' href='mailto:clementf78@gmail.com'>clementf78@gmail.com</a>
		</div>
		<p>
            Le serveur discord est accessible à l’adresse suivante :
		</p>
		<div className='blue'>
            ➜ <a className='link_blue' href='discord.beta.hackernwar.com'>discord.beta.hackernwar.com</a>
		</div>
		<p>
            Ces informations sont par ailleurs également disponibles sur la page d’accueil du site.
		</p>
		<br />
		<h3>Acceptation</h3>
		<p>
            Ainsi, l’utilisation de MyLines implique l’acceptation sans réserve de l’ensemble des conditions des « Mentions
            légales et conditions générale d’utilisation ».
		</p>
		<p>
            Les conditions émises directement par les utilisateurs n’ont aucune valeur si elles ne sont pas incluses dans
            ces présentes conditions.
		</p>
		<div className='blue is-block'>
            Ce règlement a été édité en date du <b>15 Mars 2023</b>, de ce fait, toute action transgressant ses
            règles d’utilisation effectué à une date ultérieure peut être sanctionnée.
		</div>
		<br /><br /><br />
		<span className='space' />
		<br />
		<Footer />
		<br /><br />
	</div>;
}

export default CGU;