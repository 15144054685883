import React from 'react';
import { useDrop } from 'react-dnd';
import { COMPONENT, ROW, COLUMN } from './constants';

import trash from '../../assets/img/trash.svg';

const ACCEPTS = [ROW, COLUMN, COMPONENT];

const TrashDropZone = ({ data, onDrop }) => {
	const [, drop] = useDrop({
		accept: ACCEPTS,
		drop: (item) => {
			onDrop(data, item);
		},
		canDrop: () => true,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	return <a
		className='btn red page-delete is-inline-flex'
		ref={drop}
	>
		<img className='svg_white' src={trash} />
		<span>Supprimer</span>
	</a>;
};
export default TrashDropZone;
