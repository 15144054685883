import React from 'react';
import Checkbox from '../../helpers/Checkbox';

const style1 = {
	width: 50,
	height: 25,
	borderRadius: 10
};

const style2 = {
	width: 50,
	height: 25,
};

const style3 = {
	width: 60,
	height: 38,
	marginLeft: -5,
	borderRadius: 10
};

const style4 = {
	width: 60,
	height: 38,
	marginLeft: -5,
};

const style5 = {
	width: 60,
	height: 25,
	marginLeft: -5,
	borderRadius: 10
};

const style6 = {
	width: 60,
	height: 25,
	marginLeft: -5,
};

const RowStylePicker = ({ color, shadow, handleChangeStyle, handleChangeShadow}) => {
	if (!color) {
		color = 'var(--body)';
	}
	var shadow_var = '';
	if (shadow) {
		shadow_var = '0 0 20px 0 rgba(0,0,0,.5),0 5px 5px 0 rgba(0,0,0,.24)';
	}

	return <div className='acrylic popup shadow is-absolute style-picker' style={{maxWidth: 300}}>
		<button className='is-inline-flex left' onClick={() => handleChangeStyle('row1')}>
			<span style={{ ...style1, backgroundColor: color, boxShadow: shadow_var}} />
			<span>Arrondi</span>
		</button>
		<button className='is-inline-flex left' onClick={() => handleChangeStyle('row5')}>
			<span style={{ ...style5, backgroundColor: color, boxShadow: shadow_var }} />
			<span>Arrondi large</span>
		</button>
		<button className='is-inline-flex left' onClick={() => handleChangeStyle('row3')}>
			<span style={{ ...style3, backgroundColor: color, boxShadow: shadow_var }} />
			<span>Arrondi large, sans marge</span>
		</button>
		<button className='is-inline-flex left' onClick={() => handleChangeStyle('row2')}>
			<span style={{ ...style2, backgroundColor: color, boxShadow: shadow_var }} />
			<span>Rectangle</span>
		</button>
		<button className='is-inline-flex left' onClick={() => handleChangeStyle('row6')}>
			<span style={{ ...style6, backgroundColor: color, boxShadow: shadow_var }} />
			<span>Rectangle large</span>
		</button>
		<button className='is-inline-flex left' onClick={() => handleChangeStyle('row4')}>
			<span style={{ ...style4, backgroundColor: color, boxShadow: shadow_var }} />
			<span>Rectangle large, sans marge</span>
		</button>
		<span className='my-3 space'></span>
		<Checkbox
			checked={shadow}
			className='is-inline-block'
			label={
				<span>Ombre</span>
			}
			onChange={handleChangeShadow}
		/>
		
	</div>;
};

export default RowStylePicker;
