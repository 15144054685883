import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { COLUMN } from './constants';
import DropZone from './DropZone';
import Component from './Component';

const style = {};
const Column = ({ data, column, components, handleDrop, path, isMoving, setIsMoving, setComponents, disabled }) => {
	const ref = useRef(null);

	const [{ isDragging }, drag] = useDrag({
		type: COLUMN,
		item: () => {
			setIsMoving('true');
			return {
				type: COLUMN,
				id: column.id,
				children: column.children,
				path
			};
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		}),
		end: () => {
			setIsMoving(false);
		},
		canDrag: () => !disabled
	});

	const opacity = isDragging ? 0 : 1;
	drag(ref);

	const renderComponent = (component, currentPath) => <Component
		key={component.id}
		data={data}
		disabled={disabled}
		component={component}
		components={components}
		setComponents={setComponents}
		path={currentPath}
		setIsMoving={setIsMoving}
		isMoving={isMoving}
	/>;

	return <div
		ref={ref}
		style={{ ...style, opacity }}
		className={disabled ? 'column' : 'base draggable column' }
	>
		{column.children.map((component, index) => {
			const currentPath = `${path}-${index}`;

			return <>
				<DropZone
					data={data}
					disabled={disabled}
					dropData={{
						path: currentPath,
						childrenCount: column.children.length
					}}
					onDrop={handleDrop}
					isMoving={isMoving}
				/>
				{renderComponent(component, currentPath)}
			</>;
		})}
		<DropZone
			data={data}
			disabled={disabled}
			dropData={{
				path: `${path}-${column.children.length}`,
				childrenCount: column.children.length
			}}
			onDrop={handleDrop}
			isMoving={isMoving}
			isLast
		/>
	</div>;
};
export default Column;
