import React, { useState } from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import { Link, Outlet } from 'react-router-dom';

import logo from './assets/img/logo/Logo_lite.png';

// eslint-disable-next-line no-unused-vars
import jdata from './data.json';

function NavIndex({ children, data, setData, setIsLoading, setModal, base_url }) {

	const [isLogged, setIsLogged] = useState(null);

	React.useEffect(() => {
		checkIsLogged();
	}, []);

	const checkIsLogged = async () => {
		setIsLoading(true);

		try {
			const res = await fetch(`${base_url}?account`, {
				method: 'get',
				headers: { 'Content-Type': 'application/json' },
			});

			if (res.status === 200) {
				const response = await res.json();
				setData(response);
				setIsLogged(true);
			} else {
				setIsLogged(false);
			}
		} catch (error) {
			setIsLogged(false);
		}
	};

	if (isLogged == null || isLogged == false) {
		return <div className='body'>
			<header>
				<div className='nav-head'>
					<Link to={'/index'}>
						<img src={logo} className='icone' />
					</Link>

					<span> MyLines </span>
				</div>
				{
					isLogged === null
						? <div className='mr-4'>
							<SpinnerCircularFixed color='#fff' secondaryColor='transparent' size={30} speed={100} thickness={150} />
						</div>
						: <div className='nav-link'>
							<span className='link_white'> <a href={'/login'}> Connexion </a></span>
							<span className='link_white'> <a href={'/signup'}> Inscription </a></span>
						</div>
				}
				<div className='nav-ham' onClick='menuOp()'>
					<img src='assets/img/menu.svg' className='svg_white icone' />
				</div>
			</header>
			<div className='content'>
				{children}
				<Outlet />
			</div>
		</div>;
	}

	const user = data.account.name;

	return <div className='body'>
		<header>
			<div className='nav-head'>
				<Link to={'/index'}>
					<img src={logo} className='icone' />
				</Link>

				<span> MyLines </span>
				<span className='extend'>Bonjour, {user} </span>
			</div>
			<div className='nav-link'>
				<Link to={`/company/${user}/home`} className='link_white'>
                    Ma compagnie
				</Link>
				<Link to='/settings' className='link_white'>
                    Mon compte
				</Link>
				<span onClick={() => setModal('logout')} className='link_white'> Déconnexion </span>
			</div>
			<div className='nav-ham' onClick='menuOp()'>
				<img src='assets/img/menu.svg' className='svg_white icone' />
			</div>
		</header>
		<div className='content'>
			{children}
			<Outlet />
		</div>
	</div>;
}

export default NavIndex;