import React from 'react';
import Select from 'react-select';
import { SpinnerCircularFixed } from 'spinners-react';

import undo from '../../assets/img/undo.svg';
import redo from '../../assets/img/redo.svg';
import reset from '../../assets/img/reset.svg';
import save from '../../assets/img/save.svg';

function HeadBar({ data, page, handleOnChangePage, handleUpdate, isLoadingUpdate, handleSave, isLoadingSave, isLoading }) {

	const pageList = [];
	pageList.push({
		'value': 'home',
		'label': 'Accueil'
	});
	data.pages.forEach(item => {
		pageList.push({
			'value': item.id,
			'label': item.name
		});
	});
	const pageSelected = pageList.filter(item => item.value == page);

	return <div className='settings-headbar'>
		<button className='is-inline-flex ml-3' style={{ width: 42 }} title='Annuler'>
			<img alt='Fermer' className='svg' src={undo} />
		</button>

		<button className='is-inline-flex' style={{ width: 42 }} title='Restaurer'>
			<img alt='Fermer' className='svg' src={redo} />
		</button>

		<div className='is-inline-block ml-3' style={{
			width: '30%',
			maxWidth: 250
		}}>
			<Select
				className='basic-single'
				classNamePrefix='select'
				classNames={{
					control: state => state.isFocused ? 'select active' : 'select',
					menu: () => 'select-list',
					singleValue: () => 'select-value',
					option: state => `select-option ${state.isSelected ? 'active' : ''}`,
					indicatorSeparator: () => 'select-separator',
					input: () => 'select-input'
				}}
				isSearchable={true}
				name='color'
				noOptionsMessage={() => 'Aucun résultat'}
				value={pageSelected[0]}
				onChange={handleOnChangePage}
				options={pageList}
				placeholder='Page'
			/>
		</div>

		{
			!isLoading && <>
				<button className='is-inline-flex' onClick={handleUpdate} style={{ width: '130px' }}>
					{
						isLoadingUpdate
							? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
							: <img className='svg' src={reset} />
					}
					<span>Actualiser</span>
				</button>

				<button className='is-inline-flex' onClick={handleSave} style={{ width: '155px' }}>
					{
						isLoadingSave
							? <SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={25} speed={100} thickness={150} />
							: <img className='svg' src={save} />
					}
					<span>Sauvegarder</span>
				</button>
			</>
		}

	</div>;
}

export default HeadBar;