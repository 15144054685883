import React from 'react';

import { useRef, useState } from 'react';
import { insertLink } from '../../utils/link.js';
import Icon from '../../common/Icon.jsx';
import { isBlockActive } from '../../utils/SlateUtilityFunctions.js';
import usePopup from '../../utils/usePopup.jsx';
import Checkbox from '../../../../helpers/Checkbox';
import { Transforms } from 'slate';

const LinkButton = (props) => {
	const { editor } = props;
	const linkInputRef = useRef(null);
	const [showInput, setShowInput] = usePopup(linkInputRef);
	const [url, setUrl] = useState('');
	const [showInNewTab, setShowInNewTab] = useState(false);
	const [selection, setSelection] = useState();
	const handleInsertLink = () => {
		Transforms.select(editor, selection);
		insertLink(editor, { url, showInNewTab });
		setUrl('');
		setShowInput(prev => !prev);
		setShowInNewTab(false);
	};
	const toggleLink = () => {
		setSelection(editor.selection);
		setShowInput(prev => !prev);
	};
	const handleInputChange = ({ target }) => {
		if (target.type === 'checkbox') {
			setShowInNewTab(prev => !prev);
		}
		else {
			setUrl(target.value);
		}
	};
	return (
		<div ref={linkInputRef} className='popup-wrapper'>
			{/* eslint-disable-next-line react/no-unknown-property */}
			<button className={showInput ? 'mini_btn clicked' : 'mini_btn'} active={isBlockActive(editor, 'link')} format={'link'} onClick={toggleLink}>
				<Icon icon='link' />
			</button>
			{
				showInput &&
                <div className='acrylic popup shadow is-absolute' style={{width: 200, left: -100}}>
                	<div style={{ display: 'flex', gap: '4px', margin: '5px 2px' }}>
                		<input type='text' placeholder='https://google.com' value={url} onChange={handleInputChange} />
                		<div onClick={handleInsertLink}><Icon icon='add' /></div>
                	</div>
                	<Checkbox
                		checked={showInNewTab}
                		className='is-inline-block'
                		label={
                			<span>Nouvel onglet</span>
                		}
                		onChange={handleInputChange}
                	/>
                </div>
			}
		</div>
	);
};


export default LinkButton;