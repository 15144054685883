export const colors = [
	'#8A0808',
	'#FF0000',
	'#FE9A2E',
	'#FFFF00',
	'#64FE2E',
	'#088A08',
	'#2E9AFE',
	'#0404B4',
	'#8000FF',
	'#FF00FF',
	'#DD7E6B',
	'#EA9999',
	'#F9CB9C',
	'#FFE599',
	'#B6B78A',
	'#38761D',
	'#4AC2F4',
	'#6FA8DC',
	'#B4A7D6',
	'#D5A6BD',
	'#010101',
	'#434343',
	'#666666',
	'#999999',
	'#B6B6B6',
	'#CCCCCC',
	'#D8D8D8',
	'#EEEEEE',
	'#F2F2F2',
	'#FFFFFF', 
];