import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const img = {
	width: 30,
};

import search from '../assets/img/search.svg';
import search_blue from '../assets/img/search_blue.png';

import arrivals from '../assets/img/arrival.png';
import departures from '../assets/img/departure.png';
import journeys from '../assets/img/journeys.png';
import del from '../assets/img/delete.svg';

function SearchForm({ data, defaultValue = 'journeys' }) {
	let navigate = useNavigate();
	
	const [modal, setModal] = useState(null);
	const [type, setType] = useState(defaultValue);
	const [value, setValue] = useState('');
	const [selected, setSelected] = useState(null);
	const [selected2, setSelected2] = useState(null);
	const [stops, setStops] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	function openModal() {
		if (type == 'journeys') {
			setModal('journeys');
			getStops();
		} else {
			setModal('stop');
			getStops();
		}
	}

	function handleOnClickStop(id) {
		if (modal == 'journeys2') {
			setSelected2(id);
			handleCancel();
			return;
		}

		setSelected(id);

		if (modal == 'journeys') {
			setValue('');
			setStops([]);
			setModal('journeys2');
			getStopFrom(id);
		} else {
			handleCancel();
		}

	}

	function handleOnChangeValue(event) {
		setValue(event.target.value);

		if (modal == 'journeys2') {
			getStopFrom(selected);
		} else {
			getStops();
		}
	}

	function getStops() {
		setIsLoading(true);

		let url = `https://api.mylines.fr/te/search?auth=${data.account.name}&q=${value}`;

		fetch(url, {
			method: 'get'
		})
			.then(res => res.json())
			.then(data => {
				setStops(data.stop_points);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}

	function getStopFrom(selected) {
		setIsLoading(true);

		console.log(selected);
		console.log(value);
		console.log(modal);

		let url = `https://api.mylines.fr/te/journeys?auth=${data.account.name}&from=${selected.stop_point.uic_code}&q=${value}`;

		fetch(url, {
			method: 'get'
		})
			.then(res => res.json())
			.then(data => {
				setStops(data.stop_points);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}

	function handleOnChangeType(value) {
		if (value == type) {
			return;
		}
		setType(value);
		setSelected(null);
		setSelected2(null);
	}

	function handleCancel() {
		setValue('');
		setStops([]);
		setIsLoading(false);
		setModal(null);
	}

	function getPlaceHolder(el) {
		switch (el) {
		case 'journeys':
			return "D'où partons nous ?";
		case 'journeys2':
			return 'Où allons nous ?';
		default:
			return 'Rechercher une gare';
		}
	}

	function goTo() {
		if (type == 'journeys') {
			navigate(`/company/${data.account.name}/${type}/${selected.stop_point.uic_code}/to/${selected2.stop_point.uic_code}`);
		}
		navigate (`/company/${data.account.name}/${type}/${selected.stop_point.uic_code}`);
	}
    

	return <>

		{
			modal && <div className='modal-back' key={modal}>
				<div className='modal-back'>
					<div className='modal' key='embed'>

						<div className='is-block'>
							<input
								autoFocus
								className='left is-inline-block search'
								onChange={(e) => handleOnChangeValue(e)}
								placeholder={getPlaceHolder(modal)}
								type='text'
								value={value}
							/>
							<div className='space' />
						</div>
						{
							isLoading && <div className='is-block'>
								<div className='progress-bck' />
								<div className='progress indeterminate' />
							</div>
						}
						<br />
						<div className='scrollable'>
							{
								stops.map((stop) => <button className='fluent_btn2 is-inline-flex left' key={stop.stop_point.tvs} onClick={() => handleOnClickStop(stop)}>
									<span>{stop.stop_point.name}</span>
								</button>)
							}
						</div>

						<div className='footer'>
							<button onClick={handleCancel} style={{ width: 120 }}>
								<img alt='Annuler' className='svg' src={del} />
								<span>Annuler</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		}

		<div className='sel-form p-1 is-flex'>
			<button className={type == 'journeys' ? 'sel-selected left' : 'left'} style={{ width: 135 }} onClick={() => handleOnChangeType('journeys')}>
				<img src={journeys} style={img} />
				<span style={{color: 'var(--blue-high)'}}>Itinéraires</span>
			</button>
			<button className={type == 'departures' ? 'sel-selected left' : 'left'} style={{ width: 120 }} onClick={() => handleOnChangeType('departures')}>
				<img src={departures} style={img} />
				<span style={{color: 'var(--blue-high)'}}>Départs</span>
			</button>
			<button className={type == 'arrivals' ? 'sel-selected left' : 'left'} style={{ width: 120 }} onClick={() => handleOnChangeType('arrivals')}>
				<img src={arrivals} style={img} />
				<span style={{color: 'var(--green-primary)'}}>Arrivées</span>
			</button>
		</div>

		<div className='p-3'>
			<button className='left' style={{ border: '2px solid #297CD361' }} onClick={openModal}>
				<img alt='Fermer' src={search_blue} />
				<b style={{ color: '#2674C6' }}>
					{
						selected == null
							? 'Rechercher une gare ou un itinéraire'
							: <span> {
								selected2 == null
									? selected.stop_point.name
									: `${selected.stop_point.name} ➜ ${selected2.stop_point.name}`
							} </span>
					}
				</b>
			</button>

			<br />

			<button className='blue' style={{ width: 150 }} onClick={goTo}>
				<img alt='Fermer' className='svg_white' src={search} />
				<span>Rechercher</span>
			</button>
		</div>
	</>;
}
export default SearchForm;