import { Slide } from 'react-toastify';

const block_himg = { top: '5px' };

const toast_error = {
	position: 'top-left',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	transition: Slide,
	theme: 'colored',
};



const toast_pending = {
	position: 'top-left',
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	transition: Slide,
	theme: 'colored',
	autoClose: false,
};

const wallpaper = (usr = '') => {
	return {
		background: `url(https://beta.hackernwar.com/image?serv=Wallpaper&company=${usr})`,
		backgroundPosition: 'center center',
		backgroundSize: 'cover'
	};
};

const Wallpaper = {
	background: 'url(https://beta.hackernwar.com/image?serv=Wallpaper) center center / cover',
	height: '100vh',
	left: 0,
	position: 'fixed',
	right: 0,
	top: 0,
	zIndex: '-1',
};

export { block_himg, toast_error, toast_pending, wallpaper, Wallpaper };
