import React, {useState} from 'react';

import error from '../../assets/img/trafic/error.png';
import information from '../../assets/img/trafic/information.png';
import interogation from '../../assets/img/trafic/interogation.png';
import warning from '../../assets/img/trafic/warning.png';
import expand from '../../assets/img/expand.svg';

function Log({ item }) {
	function getIconByStatus(status) {
		switch (status) {
		case 1:
			return error;
		case 2:
			return warning;
		case 3:
			return information;
		default:
			return interogation;
		}
	}

	function handleOnClickExpand() {
		setIsExpanded(!isExpanded);
	}

	const level = ['Élevée', 'Importante', 'Moyenne', 'Basse'];

	const [isExpanded, setIsExpanded] = useState(false);

	return <tr>
		<td>
			<img alt={item.level} className='mr-2' src={getIconByStatus(item.level)} style={{ width: 30 }} />
		</td>
		<td>
			<span>{level[item.level]}</span>
		</td>
		<td>
			<div className='box m-0 mb-1' style={{ border: '2px solid #868686' }}>
				<div className='log-box'>
					<div>
						<b className='is-block'>{item.title}</b>
                        ➜ <span>{item.details}</span>
					</div>
					<a className='btn is-align-self-flex-end' onClick={handleOnClickExpand} style={{ width: 42 }}>
						<img alt='' className='svg' src={expand} style={{ width: 20, transform: isExpanded ? 'rotate(180deg)' : '' }} />
					</a>
				</div>
				{
					isExpanded &&
                    <div>
                    	<span className='space' />
                    	<div>
                    		<b>Compte : </b>{item.account}
                    	</div>
                    	<div>
                    		<b>Id : </b>{item.account_name}
                    	</div>
                    	<div>
                    		<b>IP : </b><a className='link' href={`https://www.geolocation-db.com/json/${item.ip}`} rel='noreferrer' target='_blank'>{item.ip}</a>
                    	</div>
                    </div>
				}
			</div>
		</td>
		<td>
		</td>
		<td>
			<span>{item.timestamp}</span>
		</td>
	</tr>;
}

export default Log;