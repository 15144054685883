import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SpinnerCircularFixed } from 'spinners-react';
import { useParams } from 'react-router-dom';

import Row from './editpage/Row';

import { wallpaper } from '../styles';

function HomeCompany({ data }) {
	const { company } = useParams();

	if (data.page == null) {
		return <>
			<div className='Wallpaper' style={wallpaper(company)} />
			<div className='center'>
				<SpinnerCircularFixed color='var(--theme)' secondaryColor='transparent' size={50} speed={100} thickness={150} />
			</div>
		</>;
	}

	const [layout] = useState(data.page.content['layout']);
	const [components] = useState(data.page.content['components']);

	const renderRow = (row, currentPath, disabled) => <Row
		key={row.id}
		disabled={disabled}
		data={data}
		row={row}
		components={components}
		path={currentPath}
	/>;

	return <>
		<div className='Wallpaper' style={wallpaper(company)} />

		<br />
		<DndProvider backend={HTML5Backend}>
			<div>
				{layout.map((row, index) => {
					const currentPath = `${index}`;

					return renderRow(row, currentPath, true);
				})}
			</div>
		</DndProvider>

		<br /><br />
	</>;
}

export default HomeCompany;