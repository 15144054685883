import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { toast_error } from './styles';
import { Link, Outlet } from 'react-router-dom';
import { SpinnerCircularFixed } from 'spinners-react';

//TODO
//style={{ backgroundImage: getWallpaper(account), backgroundPosition: 'center center', backgroundSize: 'cover' }}

import Footer from './Footer';

import { Wallpaper } from './styles';

// eslint-disable-next-line no-unused-vars
import data_list from './data_list.json';

function Index({ setError, base_url }) {

	React.useEffect(() => {
		getAccounts();
	}, []);

	const [accounts, setAccounts] = useState([]);

	const getAccounts = async () => {
		try {
			const res = await fetch(`${base_url}?list`, {
				method: 'get',
				headers: { 'Content-Type': 'application/json' },
			});

			if (res.status === 200) {
				const response = await res.json();
				setAccounts(response);
			} else {
				toast.error('Le serveur a rencontré une erreur. ', toast_error);
				setError(true);
			}
		} catch (error) {
			toast.error('Récupération des informations impossible.', toast_error);

			// setAccounts(data_list); //TODO
		}
	};

	const getWallpaper = (account) => {
		if (account.images.Wallpaper == false) {
			return `url("image?serv=Wallpaper&company=${account.name}")`;
		}
		return 'url("image?serv=Wallpaper")';
	};

	const getLogo = (account) => {
		if (account.images.Wallpaper == false) {
			return `image?serv=Logo&company=${account.name}`;
		}
		return 'image?serv=Logo';
	};

	return <>
		<div className='Wallpaper' style={Wallpaper} />

		<br />
		<div className='m-5 p-5'>
			<h1 className='index-title'>
                Sélectionnez une compagnie
			</h1>
			<br /><br />
			<h3 className='index-title'>
                Vous ne voyez pas votre compagnie ?
			</h3>
			<a href='signup' className='index-title link'>
                Créer un compte
			</a>
		</div>
		<br />
		<br />

		<div className=''>
			{
				accounts.length == 0 &&
                <div className='center'>
                	<SpinnerCircularFixed color='var(--text)' secondaryColor='transparent' size={50} speed={100} thickness={150} />
                </div>

			}
			{
				accounts.map((account, index) =>
					<Link to={`/company/${account.name}/home`} key={account.name} >
						<div className='index-column acrylic'>
							<div style={{ animation: '0.8s ease 0s 1 normal none running SettingsOptions' }}>
								<img src={getLogo(account)} className='index-img' />
								<h2>{account.name}</h2>
								<p>{account.description}</p>
							</div>
						</div>
					</Link>
				)
			}
		</div>

		<br /><br />

		<div className='acrylic over-acrylic'>
			<Footer />
		</div>
	</>;
}

export default Index;