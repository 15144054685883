import React, { useState } from 'react';
import { CirclePicker } from 'react-color';

import { colors } from '../../defaultColors.js';

import check from '../../assets/img/check.svg';

const RowColorPicker = ({ color, handleChangeColor }) => {
	const [hexValue, setHexValue] = useState('');
	const [validHex, setValidHex] = useState();
	const isValideHexSix = new RegExp('^#[0-9A-Za-z]{6}');
	const isValideHexThree = new RegExp('^#[0-9A-Za-z]{3}');

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!validHex) return;
		handleChangeColor({
			hex: hexValue
		});
		setValidHex('');
		setHexValue('');

	};

	const handleHexChange = (e) => {
		e.preventDefault();
		const newHex = e.target.value;
		setValidHex(isValideHexSix.test(newHex) || isValideHexThree.test(newHex));
		setHexValue(newHex);
	};

	return <div className='acrylic popup shadow is-absolute color-picker'>
		<CirclePicker circleSize={25}
			width={350}
			circleSpacing={10}
			className='mt-4'
			color={color}
			colors={colors}
			onChange={handleChangeColor}
		/>

		<div className='is-flex is-align-items-end'>
			<CirclePicker circleSize={25}
				width={45}
				circleSpacing={10}
				className='mt-4'
				color={color}
				colors={['var(--body)']}
				onChange={() => handleChangeColor('body')}
			/>
			<span className='is-inline-block'>Automatique</span>
		</div>
		<div className='is-flex is-align-items-end transparent-circle-picker'>
			<CirclePicker circleSize={25}
				width={45}
				circleSpacing={10}
				className='mt-4'
				color={color}
				colors={['var(--transparent)']}
				onChange={() => handleChangeColor('transparent')}
			/>
			<span className='is-inline-block'>Transparent</span>
		</div>
		<span className='my-3 space'></span>
		<form onSubmit={handleFormSubmit}>
			<div className='round_size' style={{ background: validHex ? hexValue : '#000000' }}></div>
			<input type='text' placeholder='#000000' value={hexValue} onChange={handleHexChange} style={{ border: validHex === false ? '2px solid red' : '2px solid var(--transparent)' }} />
			<button className='green' style={{ color: validHex ? 'green' : '', width: 52 }} type={'submit'}>
				<img className='svg_white check' src={check} />
			</button>
		</form>
	</div>;
};

export default RowColorPicker;
