import React from 'react';
import { toast } from 'react-toastify';
import { toast_pending } from '../styles';

import trash from '../assets/img/trash.svg';


function Apps({ data, handleOnChange, updateData }) {
	const toastId = React.useRef(null);

	async function handleRevokeApp(event, id) {
		event.preventDefault();
		event.currentTarget.blur();

		toastId.current = toast('Patientez...', toast_pending);

		const { code } = await updateData('revoke_app', id);
		const index = data['apps'].findIndex((app) => app['id'] === id);

		if (code === 200) {
			handleOnChange({ 'apps': { $splice: [[index, 1]] } });
			toast.update(toastId.current, { render: 'L’application a été révoqué.', type: toast.TYPE.SUCCESS, autoClose: 5000 });
		} else {
			toast.update(toastId.current, { render: 'Une erreur s’est produite.', type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	return (
		<div className='block'>
			<h3>Révoquez l’accès des applications à votre compte</h3>
			<table>
				<tbody>
					{
						data['apps'].map((app) => {
							return <tr key={app['name']}>
								<td>
									<img src={app['img']} style={{ maxWidth: '80px' }} />
									<br />
									<b>{app['name']}</b>
								</td>
								<td>
									<button className=' red' onClick={(e) => handleRevokeApp(e, app['id'])} style={{ width: '125px' }}>
										<img className='svg_white' src={trash} />
										<span>Révoquer</span>
									</button>
								</td>
							</tr>;
						})
					}

				</tbody>
			</table>
		</div>

	);
}

export default Apps;