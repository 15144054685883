import React, { useState } from 'react';
import Toggle from 'react-toggle';
import { SpinnerCircularFixed } from 'spinners-react';
import { toast } from 'react-toastify';
import { block_himg, toast_error } from '../styles';

import check from '../assets/img/check.svg';
import visibility from '../assets/img/visibility.svg';
import invisible from '../assets/img/invisible.svg';

function Security({ data, handleOnChange, updateData }) {
	async function handleChangeVisible(event) {
		setIsLoadingVisible(true);
		const value = event.target.checked;
		const { code, response } = await updateData('visible', value);

		if (code === 200) {
			handleOnChange({ 'account': { 'visible': { $set: (response['response']['value'] === true) } } });
		} else {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingVisible(false);
	}

	function handleChangePassword(event) {
		const value = event.target.value;
		setPassword(value);
	}
	function handleChangeNewPassword(event) {
		const value = event.target.value;
		setNewPassword(value);
	}
	function handleChangeNewPassword2(event) {
		const value = event.target.value;
		setNewPassword2(value);
	}
	async function handleSavePassword(event) {
		event.preventDefault();
		event.currentTarget.blur();

		if (newPassword.length < 8 || newPassword2.length < 8) {
			setMessage('Votre mot de passe doit comporter au moins 8 caractères');
		} else if (newPassword !== newPassword2) {
			setMessage('Les mots de passe ne correspondent pas');
		} else {
			setMessage('');
			setIsLoadingPassword(true);
		}

		setIsLoadingPassword(true);
		const value = { password: password, new_password: newPassword, new_password2: newPassword2 };
		const { code, response } = await updateData('password', value);

		if (!code === 200) {
			toast.error(response['error']['details'], toast_error);
		}
		setIsLoadingPassword(false);

		toast.info(response['response']['value'], toast_error);

		setPasswordSuccess(true);
		setTimeout(setPasswordSuccess(false), 10000);
	}

	const [isLoadingVisible, setIsLoadingVisible] = useState(false);
	const [isLoadingPassword, setIsLoadingPassword] = useState(false);
	const [passwordSuccess, setPasswordSuccess] = useState(false);
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPassword2, setNewPassword2] = useState('');
	const [message, setMessage] = useState('');

	return (
		<div>
			{
				data['account']['visible'] === true
					? <div className='green'>
						<img className='svg' src={visibility} style={block_himg} /> <b>Votre compagnie est visible et accessible à tous les utilisateurs de MyLines.</b>
					</div>

					: <div className='blue'>
						<img className='svg' src={invisible} style={block_himg} /> <b>Votre compagnie est privée, seul vous pouvez voir votre compagnie.</b>
					</div>
			}

			<div className='block'>
				<h3>Visibilité de votre compagnie</h3>
                Si votre compagnie est privée, seul vous pouvez accéder à votre compagnie. <br />
				<a style={{ color: 'var(--red-primary)' }}> Vous devez avoir liée votre compte Train Empire pour rendre votre compagnie publique. </a>
				<br /><br />
				<Toggle
					checked={data['account']['visible']}
					icons={false}
					id='visible'
					onChange={handleChangeVisible}
				/>
				{isLoadingVisible
					? <SpinnerCircularFixed color='#2674c6' secondaryColor='transparent' size={20} speed={100} thickness={150} />
					: <label htmlFor='visible'>{data['account']['visible'] ? 'Publique' : 'Privée'}</label>
				}

			</div>

			{
				data['account']['g_sign'] === true
					? <div className='block'>
						<h3>Authentification</h3>
                        Votre compte MyLines est lié à un compte Google pour l’authentication.
						<br />
						<div className='blue'>
                            Vous ne pouvez-vous connecter qu’avec ce dernier, ainsi si vous perdez l’accès à votre compte Google, vous ne pourrez plus vous connecter à ce compte MyLines.
						</div>
					</div>
					: <div className='block'>
						<h3>Modifier votre mot de passe</h3>
                        Privilègiez un mot de passe unique pour chaque site, contenant des majuscules, chiffres et caractères spéciaux.
						<b className='is-block'><a className='link_blue' href='https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-un-bon-mot-de-passe'> Voir les préconisations de la CNIL</a></b>
						<br />
						<form className='fluent_form'>
							{
								message !== '' &&
                                <div className='is-red'>{message}</div>
							}
							<br />
							<input
								onChange={handleChangePassword}
								pattern='.{8,}'
								placeholder='Votre mot de passe actuel'
								type='password'
								value={password}
							/>
							<br />
							<input
								onChange={handleChangeNewPassword}
								pattern='.{8,}'
								placeholder='Votre nouveau mot de passe'
								title='Mot de passe de plus de 8 caractères'
								type='password'
								value={newPassword}
							/>
							<input
								onChange={handleChangeNewPassword2}
								pattern='.{8,}'
								placeholder='Vérification du nouveau mot de passe'
								title='Mot de passe de plus de 8 caractères'
								type='password'
								value={newPassword2}
							/>
							<br />
							<button className=' blue' onClick={handleSavePassword} type='submit'>
								<span >{isLoadingPassword
									? <div>
										<SpinnerCircularFixed color='#ffffff' secondaryColor='transparent' size={20} speed={100} thickness={150} />
									</div>
									: passwordSuccess
										? <img alt='Enregistré avec succès' className='svg_white check' src={check} />
										: 'Modifier mon mot de passe'
								}</span>
							</button>
						</form>
					</div>

			}
		</div>

	);
}

export default Security;