import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { settings_route, help_route, admin_route } from './routes';

import ScrollBlock from './Scroll';

function Settings({data}) {
	const location = useLocation();
	let path = location.pathname;
	path = path.replace('/settings/', '');

	const routes = [].concat(settings_route, help_route, admin_route);
	const route = routes.find(item => item.id === path) || routes[0];

	if (typeof route === 'undefined') {
		return <Outlet />;
	}

	return <>
		<ScrollBlock
			data={data}
		/>

		<div className='settings-options' key={route.id}>
			{
				!route['is-hideed-label']
                && <>
                	<img alt={route.label} className='himg svg' src={route.img} />
                	<h2 className='ml-2 is-inline-block'>{route.label}</h2>
                </>
			}
			<Outlet />
		</div>
	</>;
}

export default Settings;