import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Link } from 'react-router-dom';

import LimitedInput from './LimitedInput';

import grip from '../assets/img/grip.svg';
import visibility from '../assets/img/visibility.svg';
import invisible from '../assets/img/invisible.svg';
import pin from '../assets/img/pin.svg';
import unpin from '../assets/img/unpin.svg';
import trash from '../assets/img/trash.svg';
import edit from '../assets/img/write_on_paper.svg';
import update from 'react-addons-update';

const ItemTypes = {
	PAGE: 'page',
};

function Page({ id, index, page, moveCard, disposition, setDisposition, handleRemovePage }) {
	const ref = useRef(null);
	const [{ handlerId }, drop] = useDrop({
		accept: ItemTypes.PAGE,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			const clientOffset = monitor.getClientOffset();

			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveCard(dragIndex, hoverIndex);

			item.index = hoverIndex;
		},
	});
	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.PAGE,
		item: () => {
			return { id, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	const opacity = isDragging ? 0.5 : 1;
	drag(drop(ref));

	function handleOnChangePageName(value) {
		setDisposition(disposition => update(disposition, { [index]: { 'name': { $set: value } } }));
	}

	function handleToogleVisibility() {
		setDisposition(disposition => update(disposition, { [index]: { 'visibility': { $set: !page.visibility } } }));
	}

	function handleTooglePinned() {
		disposition.map((page, i) => setDisposition(disposition => update(disposition, { [i]: { 'pinned': { $set: false } } })));
		setDisposition(disposition => update(disposition, { [index]: { 'pinned': { $set: !page.pinned } } }));
	}

	return <div className='box p-0' data-handler-id={handlerId} ref={ref} style={{ opacity }}>
		<img className='svg ml-0 drag-grip' src={grip} />
		<button className='is-inline-flex is-relative'
			style={{ width: 46, height: 46, top: 6 }}
			onClick={handleTooglePinned}
		>
			{
				page.pinned
					? <img className='svg ml-0' src={pin} />
					: <img className='svg ml-0' src={unpin} />
			}
		</button>
		<button className='is-inline-flex is-relative'
			style={{ width: 46, height: 46, top: 6 }}
			onClick={handleToogleVisibility}
		>
			{
				page.visibility
					? <img className='svg ml-0' src={visibility} />
					: <img className='svg ml-0' src={invisible} />
			}
		</button>
		<LimitedInput
			className='is-inline-block ml-2 mr-2'
			hideCounter
			maxLength={50}
			onChange={handleOnChangePageName}
			placeholder='Nom de la page'
			style={{ width: '30%' }}
			type='text'
			value={page.name}
		/>
		<Link to={`/editpage/${page.id}`} className='btn is-inline-flex is-relative'
			style={{ width: 105, height: 46, top: 8 }}>
			<img className='svg' src={edit} />
			<span>Editer</span>
		</Link>
		<button
			className='is-inline-flex is-relative red'
			style={{ width: 46, height: 46, top: 8, float: 'right' }}
			onClick={() => handleRemovePage(page)}
		>
			<img className='svg_white ml-0' src={trash} />
		</button>
	</div >;
}

export default Page;