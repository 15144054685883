import React from 'react';
import { toast } from 'react-toastify';

import { toast_pending } from '../../styles';

import settings from '../../assets/img/settings.svg';

function Admin({ data, handleOnChange, updateData }) {
	const toastId = React.useRef(null);

	async function handleRevokeApp(event) {
		event.preventDefault();
		event.currentTarget.blur();

		toastId.current = toast('Patientez...', toast_pending);

		const { code, response } = await updateData('admin_is_debug', !data['admin']['is-debug']);

		if (code === 200) {
			handleOnChange({ 'admin': { 'is-debug': { $set: response['response']['value'] } } });
			if (response['response']['value']) {
				toast.update(toastId.current, { render: 'Mode debug activé.', type: toast.TYPE.SUCCESS, autoClose: 5000 });
			} else {
				toast.update(toastId.current, { render: 'Mode debug désactivé.', type: toast.TYPE.SUCCESS, autoClose: 5000 });
			}
		} else {
			toast.update(toastId.current, { render: 'Une erreur s’est produite.', type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	return (
		data['admin']['is-debug']
			? <div className='green is-flex-direction-column is-align-items-start'>
				<h3>Session Débug</h3>
				<br />
                Forcer l’affichage des erreurs.
				<br />
				<b> La génération des fiches horaires risque de ne pas fonctionner si des erreurs s’affichent</b>
				<br />
				<b> Les erreurs rencontrées ne seront par rapportées !</b>
				<br /><br />
				<button className='mt-4  red left' onClick={handleRevokeApp} style={{ textAlign: 'left' }}>
					<img className='svg_white' src={settings} />
					<span>Désactiver le debug</span>
				</button>
			</div>
			: <div className='orange is-flex-direction-column is-align-items-start'>
				<h3>Session Débug</h3>
				<br />
                Forcer l’affichage des erreurs.
				<br />
				<b> La génération des fiches horaires risque de ne pas fonctionner si des erreurs s’affichent</b>
				<br />
				<b> Les erreurs rencontrées ne seront par rapportées !</b>
				<br /><br />
				<button className='mt-4  red left' onClick={handleRevokeApp} style={{ textAlign: 'left' }}>
					<img className='svg_white' src={settings} />
					<span>Activer le debug</span>
				</button>
			</div>
	);
}

export default Admin;