import React from 'react';
import { toast } from 'react-toastify';

import { toast_pending } from '../../styles';

import trash from '../../assets/img/trash.svg';

function Storage({ data, updateData, handleOnChange }) {

	const images_perc = data.admin.storage.images / data.admin.storage.global;
	const data_perc = (data.admin.storage.data + data.admin.storage.images) / data.admin.storage.global;
	const reports_perc = (data.admin.storage.reports + data.admin.storage.data + data.admin.storage.images) / data.admin.storage.global;
	const cache_perc = (data.admin.storage.cache + data.admin.storage.reports + data.admin.storage.data + data.admin.storage.images) / data.admin.storage.global;
	const ml = data.admin.storage.global - (data.admin.storage.cache + data.admin.storage.reports + data.admin.storage.data + data.admin.storage.images);
	const toastId = React.useRef(null);

	async function handleOnClickClearCache(event) {
		event.preventDefault();
		event.currentTarget.blur();

		toastId.current = toast('Patientez...', toast_pending);

		const { code, response } = await updateData('admin_storage_clear', true);

		if (code === 200) {
			handleOnChange({ 'admin': { 'storage': { $set: response['response']['value'] } } });
			toast.update(toastId.current, { render: 'Cache vidé avec succès.', type: toast.TYPE.SUCCESS, autoClose: 5000 });
		} else {
			toast.update(toastId.current, { render: 'Une erreur s’est produite.', type: toast.TYPE.ERROR, autoClose: 5000 });
		}
	}

	return <div className='block'>
		<div className='block'>
			<div className='progress_size' style={{ backgroundColor: '#4f474e' }}></div>
			<div className='progress_size' style={{ backgroundColor: '#f4e838', top: '-1vmin', zIndex: 10, width: `${(images_perc) * 100}%` }}></div>
			<div className='progress_size' style={{ backgroundColor: '#58f438', top: '-2vmin', zIndex: 8, width: `${(data_perc) * 100}%` }}></div>
			<div className='progress_size' style={{ backgroundColor: '#f43838', top: '-3vmin', zIndex: 6, width: `${(reports_perc) * 100}%` }}></div>
			<div className='progress_size' style={{ backgroundColor: '#3881f4', top: '-4vmin', zIndex: 4, width: `${(cache_perc) * 100}%` }}></div>
		</div><br />

		<div style={{ position: 'relative', top: '-90px' }}>
			<h3 className='ml-2 is-inline-block'>Total</h3>  <br />
			{formatOctet(data.admin.storage.global)}

			<span className='space'></span>

			<span className='round_size' style={{ backgroundColor: '#4f474e' }}></span>
			<h3 className='ml-2 is-inline-block'>MyLines</h3>  <br />
			{formatOctet(ml)}

			<span className='space'></span>

			<span className='round_size' style={{ backgroundColor: '#f4e838' }}></span>
			<h3 className='ml-2 is-inline-block'>Images</h3> <br />
			{formatOctet(data.admin.storage.images)}

			<span className='space'></span>

			<span className='round_size' style={{ backgroundColor: '#58f438' }}></span>
			<h3 className='ml-2 is-inline-block'>Données</h3> <br />
			{formatOctet(data.admin.storage.data)} <br />
            dont import : {formatOctet(data.admin.storage.imports)}

			<span className='space'></span>

			<span className='round_size' style={{ backgroundColor: '#f43838' }}></span>
			<h3 className='ml-2 is-inline-block'>Rapports d’erreurs</h3>  <br />
			{formatOctet(data.admin.storage.reports)}

			<span className='space'></span>

			<span className='round_size' style={{ backgroundColor: '#3881f4' }}></span>
			<h3 className='ml-2 is-inline-block'>Cache</h3> <br />
			{formatOctet(data.admin.storage.cache)}
			<br /><br />
            Vider le cache peut résoudre des problèmes de mise à jour mais risque d’affecter les performances de MyLines.
			<br /><br />

			<button className=' blue left' onClick={handleOnClickClearCache}>
				<img className='svg_white' src={trash} />
				<span>Vider le cache</span>
			</button>
		</div>
	</div>;
}

function formatOctet(bytes, decimals = 2) {
	if (!+bytes) return '0 o';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['o', 'Ko', 'Mo', 'Go', 'To'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default Storage;