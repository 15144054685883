import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { COMPONENT } from './constants';

import ContentComponement from './ContentComponement';

const style = {
	borderBottom: '1px dashed var(--acrylic-ligt)',
	padding: '0.5rem 1rem',
};

const Component = ({ data, component, components, path, setIsMoving, setComponents, disabled }) => {
	const ref = useRef(null);

	const [{ isDragging }, drag] = useDrag({
		type: COMPONENT,
		item: () => {
			setIsMoving('true');
			return {
				type: COMPONENT,
				id: component.id,
				path
			};
		},
		collect: monitor => ({
			isDragging: monitor.isDragging()
		}),
		end: () => {
			setIsMoving(false);
		},
		canDrag: () => !disabled
	});

	const opacity = isDragging ? 0 : 1;
	drag(ref);

	const content = components[component.id];

	return <div
		ref={ref}
		style={{ ...style, opacity }}
		className={disabled ? 'component' : 'component draggable'}
	>
		<ContentComponement
			component={content}
			data={data}
			disabled={disabled}
			id={component.id}
			setComponents={setComponents}
		/>
	</div>;
};

export default Component;