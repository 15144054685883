import React from 'react';
import { MdFormatQuote, MdVideoLibrary } from 'react-icons/md';
import { BsTypeH1, BsTypeH2, BsTypeH3 } from 'react-icons/bs';
import { FaSuperscript, FaSubscript } from 'react-icons/fa';
import { AiOutlineInsertRowBelow, AiOutlineInsertRowRight, AiOutlineDelete } from 'react-icons/ai';

import strikethrough from '../../../assets/img/strikethrough.svg';
// import text_color from '../../../assets/img/text-color.svg';
import underline from '../../../assets/img/underline.svg';
import align_center from '../../../assets/img/align-center.svg';
// import align_justify from '../../../assets/img/align-justify.svg';
import align_left from '../../../assets/img/align-left.svg';
import align_right from '../../../assets/img/align-right.svg';
import bold from '../../../assets/img/bold.svg';
import italic from '../../../assets/img/italic.svg';

import bulleted_list from '../../../assets/img/bulleted-list.svg';
import numbered_list from '../../../assets/img/numbered-list.svg';

import link from '../../../assets/img/link.svg';
// import code from '../../../assets/img/code.svg';
import image from '../../../assets/img/image.svg';
import add from '../../../assets/img/add.svg';
import insert_table from '../../../assets/img/insert-table.svg';
import trash from '../../../assets/img/trash.svg';
import quote from '../../../assets/img/quote.svg';

const iconList = {
	bold: <img src={bold} className='svg' alt='bold' />,
	italic: <img src={italic} className='svg' alt='italic' />,
	strikethrough: <img src={strikethrough} className='svg' alt='strikethrough' />,
	underline: <img src={underline} className='svg' alt='underline' />,
	headingOne: <BsTypeH1 size={30} />,
	headingTwo: <BsTypeH2 size={30} />,
	headingThree: <BsTypeH3 size={30} />,

	blockquote: <img src={quote} className='svg' alt='quote' />,
	superscript: <FaSuperscript size={15} />,
	subscript: <FaSubscript size={15} />,
	alignLeft: <img src={align_left} className='svg' alt='align_left' />,
	alignCenter: <img src={align_center} className='svg' alt='align_center' />,
	alignRight: <img src={align_right} className='svg' alt='align_right' />,
	orderedList: <img src={numbered_list} className='svg' alt='numbered_list' />,
	unorderedList: <img src={bulleted_list} className='svg' alt='bulleted_list' />,
	link: <img src={link} className='svg' alt='link' />,
	image: <img src={image} className='svg' alt='image' />,
	add: <img src={add} className='svg' alt='add' />,
	table: <img src={insert_table} className='svg' alt='table' />,
	row: <AiOutlineInsertRowBelow size={30} />,
	column: <AiOutlineInsertRowRight size={30} />,
	removeTable: <img src={trash} className='svg' alt='trash' />
};


const Icon = (props) => {
	const { icon } = props;
	return iconList[icon];
};

export default Icon;