import React from 'react';

function FailedConnexions({ data }) {
	return <div className='block'>
		<table>
			<thead>
				<tr>
					<th>Identifiant</th>
					<th>IP</th>
					<th>Nombre de tentatives</th>
					<th>Heure</th>
				</tr>
			</thead>
			<tbody>
				{
					data.admin.failed_login.map((item, index) => <tr key={index}>
						<td>{item.account}</td>
						<td><a className='link' href={`https://www.geolocation-db.com/json/${item.ip}`} rel='noreferrer' target='_blank'>{item.ip}</a></td>
						<td>{item.attemp}</td>
						<td>{item.time}</td>
					</tr>
					)
				}
			</tbody>
		</table>
	</div >;
}

export default FailedConnexions;